import React from "react";
import PropTypes from "prop-types";

const Typography = ({
  children,
  type,
  className,
  error,
  block,
  faded,
  heavy,
  fadedTwoThirds,
  capitalize,
  padded,
  topPadded,
  leftPadded,
  rightPadded,
  bottomPadded,
  color,
  ellipsis,
  ...props
}) => {
  return (
    <span
      {...props}
      className={[
        "Typography",
        `Typography--${type}`,
        `Typography--color-${color}`,
        className,
        error ? "Typography--error" : null,
        block ? "Typography--block" : null,
        faded ? "Typography--faded" : null,
        fadedTwoThirds ? "Typography--fadedTwoThirds" : null,
        capitalize ? "Typography--capitalize" : null,
        heavy ? "Typography--heavy" : null,
        padded ? "padded" : null,
        topPadded ? "padded__top" : null,
        bottomPadded ? "padded__bottom" : null,
        leftPadded ? "padded__left" : null,
        rightPadded ? "padded__right" : null,
        ellipsis ? "Typography--ellipsis" : null,
      ]
        .filter((i) => i)
        .join(" ")}
    >
      {children}
    </span>
  );
};

export const Heading = ({ number = 1, ...props }) => (
  <Typography type={`heading${number}`} {...props} />
);
export const Subtitle = ({ number = 1, ...props }) => (
  <Typography type={`subtitle${number}`} {...props} />
);
export const Body = ({ number = 1, ...props }) => (
  <Typography type={`body${number}`} {...props} />
);
export const Overline = (props) => <Typography type="overline" {...props} />;
export const Caption = ({ number = 1, ...props }) => (
  <Typography type={`caption${number}`} {...props} />
);
export const SidebarNav = ({ number = 1, ...props }) => (
  <Typography type={`sidebarNav${number}`} {...props} />
);

export default Typography;

Typography.propTypes = {
  // type of to use
  type: PropTypes.oneOf([
    "heading1",
    "heading2",
    "heading3",
    "heading4",
    "heading5",
    "heading6",
    "subtitle1",
    "subtitle2",
    "subtitle3",
    "body1",
    "body2",
    "button",
    "overline",
    "caption1",
    "caption2",
    "sidebarNav1",
    "sidebarNav2",
    "sidebarNav3",
  ]),

  // whether or not the text is red (an error)
  error: PropTypes.bool,

  // whether or not the text should be displayed as a block
  block: PropTypes.bool,
  heavy: PropTypes.bool,

  // whether or not the text is "faded" aka lower opacity
  faded: PropTypes.bool,
  fadedTwoThirds: PropTypes.bool,

  // capitalizes the text style
  capitalize: PropTypes.bool,

  // padding options
  padded: PropTypes.bool,
  topPadded: PropTypes.bool,
  bottomPadded: PropTypes.bool,
  leftPadded: PropTypes.bool,
  rightPadded: PropTypes.bool,

  // color options
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "secondary-light",
    "primary-light",
    "on-primary",
    "success",
    "warning",
    "black",
    "black-a80",
  ]),

  // whether or not the typography should be cut off with ellipsis
  ellipsis: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.string,
};

Typography.defaultProps = {
  className: "",
  error: false,
  block: false,
  faded: false,
  fadedTwoThirds: false,
  capitalize: false,
  padded: false,
  heavy: false,
  topPadded: false,
  bottomPadded: false,
  leftPadded: false,
  rightPadded: false,
  color: "default",
  ellipsis: false,
};
