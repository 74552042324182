function convertRichTextToPlainText(richText, keyword) {
  let htmlWithLineBreaks = richText.replace(/<br\s*\/?>/gi, "\n");
  htmlWithLineBreaks =htmlWithLineBreaks.replace(/<\/li>/gi, "</li>\n");
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlWithLineBreaks;

  const paragraphs = Array.from(
    tempElement.querySelectorAll("p, h1, h2, h3, h4, h5, h6, ul, li")
  );
  const plainText = paragraphs
    .map((paragraph) => paragraph.textContent)
    .join("\n")
    .replace(/\n+/g, "\n");

  const filteredText = extractTextSnippet(plainText, keyword.toLowerCase());

  return filteredText;
}

function extractTextSnippet(text, keyword) {
  const sentences = text.split(/\n/);
  let targetSentence = null;
  for (let sentence of sentences) {
    sentence = sentence.toLowerCase();
    if (sentence.includes(keyword)) {
      targetSentence = sentence.trim();
      break;
    }
  }

  if (!targetSentence) {
    return null;
  }

  return sliceText(targetSentence, keyword);
}

function sliceText(sentence, keyword) {
  const indexPhrase = sentence.indexOf(keyword);
  let start = Math.max(indexPhrase - 60, 0);
  let end = Math.min(indexPhrase + keyword.length + 60, sentence.length);
  
  const firstSpaceBeforeStart = sentence.lastIndexOf(' ', start);
  const firstSpaceAfterEnd = sentence.indexOf(' ', end);


  if (firstSpaceBeforeStart > -1) {
    start = firstSpaceBeforeStart + 1;
  }
  if (firstSpaceAfterEnd > -1) {
    end = firstSpaceAfterEnd;
  }

  const selectedPhrase = sentence.substring(start, end);
  return selectedPhrase;
}

export default convertRichTextToPlainText;
