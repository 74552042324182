import React, { useContext } from "react";
import PropTypes from "prop-types";
import TopNav from "./TopNav";
import ScrollButton from "../modules/ScrollButton";
import { ModalContext } from "../../contexts/ModalContextProvider";
import Modal, { ModalContent } from "../modules/Modal";
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const { setOpen, open } = useContext(ModalContext);
  return (
    <div className="layout">
      <TopNav />
      <Outlet/>
      <ScrollButton/>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalContent />
      </Modal>
    </div>
  );
};


export default Layout;
