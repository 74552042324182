import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

export const WidthContext = React.createContext();

const WidthContextProvder = ({ children }) => {
  const getWidth = useCallback(() => {
    const documentWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const isMobile = documentWidth <= 991;
    const isSmall = documentWidth <= 576;
    const isLarge=documentWidth >= 1500
    let size = "xs";
    if (documentWidth >= 1500) {
      size = "xxl";
    } else if (documentWidth >= 1200) {
      size = "xl";
    } else if (documentWidth >= 992) {
      size = "lg";
    } else if (documentWidth > 768) {
      size = "md";
    } else if (documentWidth >= 576) {
      size = "sm";
    }

    return {
      documentWidth,
      isMobile,
      isSmall,
      size,
      isLarge
    };
  }, []);

  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWidth(getWidth());
      }, 150);
    };

    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [getWidth]);

  const resetWidth = useCallback(
    () => setWidth(getWidth()),
    [getWidth, setWidth]
  );
  window.resetWidth = resetWidth;

  return (
    <WidthContext.Provider value={{ ...width, resetWidth }}>
      {children}
    </WidthContext.Provider>
  );
};

WidthContextProvder.propTypes = {
  children: PropTypes.object,
};

export default WidthContextProvder;
