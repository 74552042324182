import { gql } from "@apollo/client";

export const GET_DEBUT_HEADER = gql`
query debutHeader {
    debutHeader {
      data {
        attributes {
            image {
              data {
                attributes {
                  url
                }
              }
            }
        }
      }
    }
  }
`;
