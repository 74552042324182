import { gql } from '@apollo/client';

export const GET_DEBUT_IMAGEGUIDELINES = gql`
query imageGuidelinesPage {
  imageGuidelinesPage {
    data{
      attributes{
        sideBar{
          sectionCategories(sort:"order:asc"){
            data{
              id
              attributes{
                publishedAt
                title
                slug
                order
                sections(sort:"order:asc"){
                  data{
                    id
                    attributes{
                      publishedAt
                      title
                      isUnderConstruction
                      slug
                      order
                      subsections(sort:"order:asc"){
                        data{
                          attributes{
                            publishedAt
                            title
                            order
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        header{
          title
          description
        }
      }
    }
  }
}
`