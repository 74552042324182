import { gql, useQuery } from '@apollo/client';

function buildQuery(pageNames) {
    const queries = pageNames.map((pageName) => {
      return `
        ${pageName}{
          data {
            attributes {
              sideBar {
                sectionCategories {
                  data {
                    attributes {
                      title
                      slug
                      sections {
                        data {
                          attributes {
                            title
                            slug
                            content {
                              ... on ComponentContentDescription {
                                id
                                description
                              }
                              ... on ComponentContentTip {
                                id
                                note
                              }
                            }
                            subsections {
                              data {
                                attributes {
                                  title
                                  order
                                  slug
                                  content {
                                    ... on ComponentContentDescription {
                                      id
                                      description
                                    }
                                    ... on ComponentContentTip {
                                      id
                                      note
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `;
    });

    return gql`
    query {
      ${queries.join('\n')}
    }
  `;
}

export default buildQuery;