import React from "react";
import Body from "../modules/Typography";
import { Link } from "react-router-dom";

const links = [
  {
    targetUrl: "/about/about/platform",
    label: "About",
  },
  {
    targetUrl: "/about/about/terms-use",
    label: "Terms",
  },
  {
    targetUrl: "/about/about/faq",
    label: "FAQ",
  },
  {
    targetUrl: "/about/about/contact",
    label: "Contact",
  },
];

const Footer = (props) => {
  return (
    <div className="Footer flex center">
      <div className="block center" number={2}>
        <div className="Footer__links">
          <ul>
            {links.map(link=>(
              <li key={link.targetUrl}>

              <Link to={link.targetUrl}>
              {link.label}
              </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="Footer__copyright">
          ©Disney 2023 All Rights Reserved.
        </div>
        <div className="Footer__displaimer">
          This website contains confidential and proprietary information of
          Disney and may not be disclosed by you to any third party without
          explicit consent from the Studio Experience Design team.
        </div>
      </div>
    </div>
  );
};

export default Footer;
