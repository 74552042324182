import React from 'react';
import Button from '../modules/Button';
import { Heading } from '../modules/Typography';

const LoginAdmin = (props) => {
    const {isStudioeRoute}=props
    return (
        <div>
            <Heading number={4} block className='Login__title'>{`Welcome to ${isStudioeRoute?'The Studio Design Guidelines':'The Walt Disney Studios - Debut'}`}
            </Heading>
            <a href='/auth/login'>
                <Button type='primary' className='flex center'>Sign in with MyID</Button>
            </a>
        </div>
    );
};

export default LoginAdmin;
