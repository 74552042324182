import React, { useContext } from 'react';
import DebutNav from './DebutNav';
import ScrollButton from '../modules/ScrollButton';
import Modal, { ModalContent } from '../modules/Modal';
import { ModalContext } from '../../contexts/ModalContextProvider';
import { Outlet } from 'react-router-dom';

const DebutLayout = ({ children }) => {
  const { setOpen, open } = useContext(ModalContext);
  return (
    <div>
      <DebutNav />
      <Outlet/>
      <ScrollButton />
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalContent />
      </Modal>
    </div>
  );
};

export default DebutLayout;