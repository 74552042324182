import React, { useContext, useRef } from "react";
import { ModalContext } from "../../contexts/ModalContextProvider";
import { CopyBlock, atomOneLight } from "react-code-blocks";
import copy from "copy-to-clipboard";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Icon from "./Icon";

const ParseMarkdown = ({ markdownText }) => {
  const { setContent, setOpen } = useContext(ModalContext);
  const markdownRef = useRef(null);

  const handleImageClick = (src, alt) => {
    setContent({ img: { url: src, name: alt } });
    setOpen(true);
  };

  const processHTML = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(markdownText, "text/html");

    const links = doc.querySelectorAll("a");
    links.forEach((link) => {
      const isExternal = link.hostname !== window.location.hostname;

      if (isExternal) {
        link.setAttribute("target", "_blank");
      }
    });

    return doc.body.innerHTML;
  };

  const markdownWithClickEvents = processHTML();

  const options = {
    replace: (node) => {
      const { attribs, children, name, next } = node;
      if (!attribs) return;

      if (node.name === "code" && node.parent?.name==="pre") {
        const language = attribs.class?.split("-")[1];
        return (
          <>
           {language!=='plaintext' && <span className="title_code">{language}</span> } 
            <CopyBlock
              text={children[0].data}
              language={language}
              showLineNumbers={true}
              theme={atomOneLight}
              codeBlock={{ lineNumbers: false, wrapLines: true }}
              onCopy={() => copy(children[0].data)}
            />
          </>
        );
      }
      if (node.name === "img") {
        const { alt, src, style } = node.attribs;
        const parsedStyle = style
          ? style.split(";").reduce((acc, rule) => {
              const [property, value] = rule
                .split(":")
                .map((str) => str.trim());
              if (property && value) {
                acc[property] = value;
              }
              return acc;
            }, {})
          : {};

        return (
          <LazyLoadImage
            onClick={() =>
              node.parent?.name !== "a" && handleImageClick(src, alt)
            }
            src={src}
            alt={alt}
            style={parsedStyle}
          />
        );
      }
      if (
        node.name === "span" &&
        attribs.class === "color-mode" &&
        (children[0]?.data === "HEX" ||
          children[0]?.data === "RGB" ||
          children[0]?.data === "RGBA")
      ) {
        const nextSibling = next;
        if (nextSibling && nextSibling.data) {
          const colorValue = nextSibling.data.trim();
          const copyHandler = () => copy(colorValue);
          return (
            <>
            <Icon onClick={copyHandler} size='medium' name='copy' />
              <span className={attribs.class}>{children[0]?.data}</span>
            </>
          );
        }
      }
    },
  };

  return (
    <div className="markdown-parser" ref={markdownRef}>
      {parse(markdownWithClickEvents, { replace: options.replace })}
    </div>
  );
};

export default ParseMarkdown;
