import React, { useContext, useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import { useQuery } from "@apollo/client";
import buildQuery from "../../utils/buildQuery";
import findSectionsWithKeyword from "../../utils/findSectionWithKeyword";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { SearchContext } from "../../contexts/SearchContextProvider";

const SearchBar = ({ pageNames, setIsSearchOpen }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const { data: searchData = {} } = useQuery(buildQuery(pageNames));
  const [searchResults, setSearchResults] = useState(null);
  const inputRef = useRef(null);
  const searchRef = useRef(null);
  const location = useLocation();
  let debounceTimeout = null;

  useEffect(() => {
    if (inputVisible) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (inputVisible) {
      resetSearch();
    }
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        resetSearch();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const resetSearch = () => {
    inputRef.current.value = "";
    setSearchResults(null);
    setIsSearchOpen(false);
    setInputVisible(false);
  };

  const handleOpen = () => {
    setInputVisible(true);
    setIsSearchOpen(true);
  };

  const handleInput = () => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      const keyword = inputRef.current.value.toLowerCase();
      setSearchResults(keyword? findSectionsWithKeyword(searchData, keyword) : null);
    }, 300);
  };

  return (
    <div ref={searchRef} className="SearchBar">
      <div className={`${!inputVisible ? "hide" : ""}`}>
        <input
          type="text"
          ref={inputRef}
          placeholder="Search"
          className="SearchBar__input"
          onChange={handleInput}
        />
        <Icon className="SearchBar__input__icon" size={"large"} name="search" />
      </div>
      <button
        className={`SearchBar__button ${inputVisible? "hide":''}`}
        onClick={handleOpen}
        aria-label="search_button"
      >
        <Icon
          className="SearchBar__button__icon"
          size={"large"}
          name="search"
        />
      </button>
      {searchResults && (
        <SearchListResult
          searchResults={searchResults}
          keyword={inputRef.current.value}
        />
      )}
    </div>
  );
};

const SearchListResult = ({ searchResults, keyword, resetSearch }) => {
  const {setSearchText} = useContext(SearchContext)
  function highlightMatches(text, keyword) {
    const regex = new RegExp(keyword, "gi");
    const highlightedText = text.replace(
      regex,
      (match) => `<strong style="text-decoration: underline">${match}</strong>`
    );
    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  }

  return (
    <div className="SearchBar__searchResultsContainer">
      <div className="SearchBar__searchResultsContainer__result">
        {!searchResults.length ? (
          <span>No Results</span>
        ) : (
          <ul className="SearchBar__searchResultsContainer__result__list">
            {searchResults.map((result, index) => (
              <li
                key={index}
                className="SearchBar__searchResultsContainer__result__list__item"
              >
              <HashLink to={result.url.toLowerCase()}>
                <div onClick={() => [resetSearch, setSearchText(result.sectionContent || result.subsectionContent)]}>
                    <span>
                      {highlightMatches(
                        `${result.page} / ${result.section}  ${
                          result.subsection ? "/ " + result.subsection : ""
                        }`,
                        keyword
                      )}
                    </span>
                    {(result.sectionContent || result.subsection) && (
                      <>
                        <hr/>
                        {result.sectionContent && (
                          <span>
                            {highlightMatches(result.sectionContent, keyword)}
                          </span>
                        )}
                        {result.subsection && (
                          <span>
                            {highlightMatches(
                              result.subsectionContent || result.subsection,
                              keyword
                            )}
                          </span>
                        )}
                      </>
                    )}
                </div>
              </HashLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default React.memo(SearchBar);
