const copySvg = async (svgId) => {
  const svgElement = document.querySelector(`#${svgId}`)
  if (svgElement) {
    const svgUrl = svgElement.src
    try {
      const response = await fetch(svgUrl)
      if (response.ok) {
        const svgCode = await response.text()
        const textArea = document.createElement('textarea')
        textArea.value = svgCode
        document.body.appendChild(textArea)
        textArea.select()
        try {
          document.execCommand('copy')
          console.log('SVG Copied!')
        } catch (error) {
          console.error('SVG Copy error:', error)
        } finally {
          document.body.removeChild(textArea)
        }
      }
    } catch (error) {
      console.error('SVG Copy error:', error)
    }
  }
}

export default copySvg
