import { gql } from '@apollo/client';

export const GET_GUIDELINE = gql`
query guidelinesPage($VIEWER_TYPE: PublicationState!) {
    guidelinesPage {
    data{
      attributes{
        sideBar{
          sectionCategories(sort:"order:asc", publicationState:$VIEWER_TYPE){
            data{
              id
              attributes{
                publishedAt
                title
                order
                slug
                sections(sort:"order:asc", publicationState:$VIEWER_TYPE){
                  data{
                    id
                    attributes{
                      publishedAt
                      title
                      isUnderConstruction
                      order
                      slug
                      subsections(sort:"order:asc", publicationState:$VIEWER_TYPE){
                        data{
                          id
                          attributes{
                            publishedAt
                            title
                            order
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`