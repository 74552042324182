import { gql } from "@apollo/client";
export const GET_ICONS_COLLECTIONS = gql`
  query {
    iconCollections(sort: "collectionName:asc", pagination: { limit: 20 }) {
      data {
        id
        attributes {
          collectionName
          collectionPackage {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
