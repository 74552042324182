const downloadFile = async (fileUrl, fileTitle, formatToDownload = null, imageColor=null,donwloadSize=null) => {
  try {
    const fileName = fileTitle
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("_", "-");

    const response = await fetch(fileUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-store',
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch File: ${response.statusText}`);
    }

    const clonedResponse = response.clone();

    const blob = await clonedResponse.blob();
    let svgText = await response.text();
    const contentType = response.headers.get('Content-Type');

    
    if (imageColor) {
      const modifiedSvgText = svgText.replace(/<path(.*?)fill="[^"]*"/g, `<path$1fill="${imageColor}"`);
      svgText = modifiedSvgText;
    }

    if (donwloadSize) {
      
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = xmlDoc.querySelector('svg');

      if (svgElement) {
        svgElement.setAttribute('width', donwloadSize);
        svgElement.setAttribute('height', donwloadSize);

        svgText = new XMLSerializer().serializeToString(xmlDoc);
      }
    }

    if (formatToDownload && formatToDownload !== 'svg') {
   
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const blobUrl = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = fileName.replace(/.svg$/, `.${formatToDownload}`);
          a.click();

          URL.revokeObjectURL(blobUrl);
        }, `image/${formatToDownload}`);
      };

      img.src = `data:image/svg+xml,${encodeURIComponent(svgText)}`;
    } else {
      if (contentType.startsWith('image/svg+xml')) {
      
      const blobImage = new Blob([svgText], { type: 'image/svg+xml' });
      const blobUrl = URL.createObjectURL(blobImage);

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
      a.click();

      URL.revokeObjectURL(blobUrl);
      }else{
        const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      }
    }
  } catch (error) {
    console.error("Download error:", error);
  }
};

export default downloadFile;
