import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { ME } from "../queries";
import { useQuery } from "@apollo/client";
import Login from "../components/pages/Login";

export const UserContext = React.createContext();

const UserComponent = ({ children }) => {
  const cookies = new Cookies();

  const [user, setUser] = useState({
    id: "",
    email: "",
    role: { name: "" },
  });

  const location = useLocation();
  const { loading, error, data, refetch } = useQuery(ME, {
    errorPolicy: "all",
  });

  useEffect(() => {
    if (user.id !== "") {
      refetch();
    }
  }, [user.id]);

  useEffect(() => {
    if (data !== undefined) {
      const { user } = data;
      setUser({ ...user, vendors: [], teams: [] });
      cookies.set("user", JSON.stringify(user));
    }
  }, [data]);

  let content = <Login />;
  if (user.id || location.pathname === "/reset-password") {
    content = children;
  }
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {content}
    </UserContext.Provider>
  );
};

UserComponent.propTypes = {
  children: PropTypes.object,
};

export default UserComponent;
