import React, { useState } from "react";
import Icon from "../modules/Icon";
import Button from "../modules/Button";
import IconCard from "../modules/IconCard";
import { LoadingIcon } from "../modules/Loading";
import MenuSurface from "../modules/MenuSurface";
import useIcons from "../../hooks/useIcons";
import downloadFile from "../../utils/downloadFile";
import downloadIconsPackage from "../../utils/downloadPackage";

const IconsContainer = () => {
  const {
    displayIcons,
    iconsCategory,
    iconsCollections,
    loadedIcons,
    menuSurfaceOptions,
    sort,
    totalIcons,
    filterByCollection,
    downloadProgress,
    setDownloadProgress,
    handleIconsSearch,
  } = useIcons();

  return (
    <>
      {loadedIcons.length === 0 ? (
        <div className="IconsContainerLoader">
          <div className="IconsContainerLoader__Wrapper">
            <LoadingIcon />
          </div>
          <div className="IconsContainerLoader__Progress">
            {`Loading ${loadedIcons.length} of ${totalIcons} icons`}
          </div>
        </div>
      ) : (
        <div className="IconsContainerToolbars">
          <div className="IconsContainerToolbars__One">
            <div>
              <div className="IconsContainerToolbars__One__Livesearch">
                <div className="IconsContainerToolbars__One__Livesearch__Icon">
                  <Icon name="search" />
                </div>
                <input
                  type="text"
                  placeholder="Search icons"
                  onChange={handleIconsSearch}
                />
              </div>
            </div>
            <div>
              <div>
                {iconsCollections?.length > 1 && (
                  <MenuSurface options={iconsCollections}>
                    {filterByCollection === "99"
                      ? `Filter by collection: All`
                      : `Filter by collection: ${
                          iconsCollections.find(
                            (collection) => collection.id === filterByCollection
                          ).name
                        }`}
                    <Icon name="caret-down" />
                  </MenuSurface>
                )}
              </div>
              <div>
                <MenuSurface options={menuSurfaceOptions}>
                  {sort && sort !== "desc"
                    ? "Sort by: Title A-Z"
                    : "Sort by: Title Z-A"}
                  <Icon name="caret-down" />
                </MenuSurface>
              </div>
            </div>
          </div>
          <div className="IconsContainerToolbars__Two">
            <div>{`${displayIcons.length} Icons`}</div>
            <div>
              {!downloadProgress && (
                <Button
                  type="surface"
                  icon="arrow-circle-down"
                  onClick={() =>
                    filterByCollection === "99"
                      ? downloadIconsPackage(
                          0,
                          iconsCategory.id,
                          iconsCategory.name,
                          setDownloadProgress
                        )
                      : downloadIconsPackage(
                          1,
                          filterByCollection,
                          iconsCollections.find(
                            (collection) => collection.id === filterByCollection
                          ).name,
                          setDownloadProgress
                        )
                  }
                >
                  {filterByCollection === "99"
                    ? `Download all icons`
                    : `Download ${
                        iconsCollections.find(
                          (collection) => collection.id === filterByCollection
                        ).name
                      } collection`}
                </Button>
              )}
              {downloadProgress && (
                <div>{`Preparing your download ${downloadProgress}%`}</div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="IconsContainer">
        {displayIcons?.map((icon) => (
          <IconCard icon={icon} key={icon.id} />
        ))}
      </div>
    </>
  );
};
export default IconsContainer;
