import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Caption } from '../modules/Typography';
import Icon from '../modules/Icon';

const BottomNav = ({ prevSection, nextSection }) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('/');
    useEffect(() => {
        if (location.pathname.length > 1) {
            const paths = location.pathname.split('/');
            const pathRoute = paths[1] || '';
            setCurrentPage(pathRoute)
        }
    }, [location.pathname]);
  
    return (
        <div className='BottomNav'>
            <ul className=' BottomNav__buttons'>
                {prevSection?.category &&
                    <li className='BottomNav__item BottomNav__prev'>
                        <Link className='BottomNav__item__item-link  flex BottomNav__item__item-right' to={`/${currentPage}/${prevSection.category.slug}/${prevSection.section.slug}` || '#'}>
                            <div className='BottomNav__right'>
                                <Caption type='button' className='BottomNav__title'>{prevSection.category.title || ''}</Caption>
                                <span className='BottomNav__section'>{prevSection.section.title || ''}{prevSection.section.underConstruction && <span className='BottomNav__underConstruction' alt="Under construction" title="Under construction">🚧</span>}</span>
                            </div>
                            <Icon className='BottomNav__icon' size='large' name='caret-left' />
                        </Link>
                    </li>}
                {nextSection?.category &&
                    <li  i className='BottomNav__item BottomNav__next flex'>
                        <Link className='BottomNav__item__item-link' to={`/${currentPage}/${nextSection.category.slug}/${nextSection.section.slug}` || '#'}>
                            <Icon className='BottomNav__icon' size='large' name='caret-right' />
                            <div className='flex column'>
                                <Caption type='button' className='BottomNav__title'>{nextSection.category.title || ''}</Caption>
                                <span className='BottomNav__section'>{nextSection.section.title || ''}{nextSection.section.underConstruction && <span className='BottomNav__underConstruction' alt="Under construction" title="Under construction">🚧</span>}</span>
                            </div>
                        </Link>
                    </li>}

            </ul>
            <hr/>
        </div>
    );
};


export default BottomNav;
