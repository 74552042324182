import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Row, Col } from "../modules/Grid";
import logoImage from "../../images/studio-design-logo-org.png";
import logoDebut from "../../images/debut/debut_logo.png";
import { Caption } from "../modules/Typography";
import LoginMessageBody from "../login/LoginMessageBody";
import LoginAdmin from "../login/LoginAdmin";
import bg from "../../images/splashbg.png";
import LoginVendor from "../login/LoginVendor";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { routes } from "../../routes";
// import LoginValidateCode from '../login/LoginValidateCode';
// import LoginPasswordSuccess from '../login/LoginPasswordSuccess';
// import LoginPasswordReset from '../login/LoginPasswordReset';

export const LoginContext = React.createContext();

const Login = () => {
  const cookies = new Cookies();
  const location = useLocation();

  const isStudioeRoute = routes.some((route) => {
    const pattern = new RegExp(`^${route.path.replace(/:[^/]+/g, "([^/]+)")}$`);
    return pattern.test(location.pathname);
  });
  

  useEffect(() => {
    const accessToken = cookies.get("access_token");
    if (accessToken && isTokenExpired(accessToken)) {
      cookies.remove("access_token", { path: '/' });
    }
    cookies.set("last_location", location.pathname, { path: "/" });
  }, []);

  function isTokenExpired(token) {
    if (!token) {
      return true;
    }
  
    const decodedToken = parseJwt(token);
    return (
      decodedToken && decodedToken.exp && decodedToken.exp * 1000 < Date.now()
    );
  }
  
  function parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const decodedToken = JSON.parse(Buffer.from(base64, 'base64').toString('utf-8'));
  
      return decodedToken;
    } catch (e) {
      return null;
    }
  } 

  const handleForgotPassword = async (e) => {
    try {
      e.preventDefault();
      // TO DO: logic to handleForgotPassword
    } catch (err) {
      setError("Someting went wrong. Please try again later.");
    }
  };

  return (
    <LoginContext.Provider value={{}}>
      <div
        className="Login"
        style={{
          backgroundImage: `url("${bg}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Helmet>
          <title>Studio Design Login Page</title>
        </Helmet>
        <Row className="no-gutters">
          <Col sm={12} lg={5} className="Login__sidebar-container flex column">
            <div style={{ width: "100%" }}>
              <div className="Login__sidebar">
                <img
                  className="Login__logo"
                  src={isStudioeRoute ? logoImage : logoDebut}
                  alt="logo"
                />

                <LoginAdmin isStudioeRoute={isStudioeRoute} />
              </div>
              <div className="Login__authdivider">
                <span className="Login__line__left" />
                <span>or</span>
                <span className="Login__line__right" />
              </div>
              <div className="Login__sidebar">
                <LoginVendor />
              </div>
            </div>
            <div className="Login__footer">
              <Caption className="Login__copyright flex center" number={2}>
                ©Disney 2023. All Rights Reserved.
              </Caption>
            </div>
          </Col>
          <Col sm={12} lg={8} className="Login__message-body">
            <LoginMessageBody authorizedUser />
          </Col>
        </Row>
      </div>
    </LoginContext.Provider>
  );
};

export default Login;
