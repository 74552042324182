import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { GET_HOME } from "../../queries";
import { Heading, Body } from "../modules/Typography";
import Button from "../modules/Button";
import HeroHeader from "../modules/HeroHeader";
import Footer from "../layout/Footer";
import Loading from "../modules/Loading";

export const HomeContext = React.createContext();

const Home = () => {
  const { data, loading, error } = useQuery(GET_HOME);
  const { header = {}, cardModule = {} } =
    data?.homePage?.data?.attributes || {};
  const {
    title,
    description: subtitle,
    sectionButton,
    image: { data: { attributes: { url: imageUrl = "#" } = {} } = {} } = {},
  } = header;
  const { moduleTitle, moduleDescription, moduleCard } = cardModule;

  const heroHeader = {
    title,
    subtitle,
    imageUrl: imageUrl,
  };

  const contentModule = {
    title: moduleTitle,
    sectionButton,
    description: moduleDescription,
    cards: moduleCard,
  };

  if (loading) return <Loading />;
  return (
    <HomeContext.Provider value={{}}>
      <Helmet>
        <title>Studio Design Platform</title>
      </Helmet>
      <HeroHeader
        title={heroHeader.title}
        subtitle={heroHeader.subtitle}
        imageSrc={heroHeader.imageUrl}
        isSecundary={false}
        infoPosition={heroHeader.infoPosition}
        customHeader={true}
        button={ sectionButton?.route ?(
          <Link to={sectionButton.route}>
            <Button size="regular" color="default" type="primary">
              {sectionButton.label}
            </Button>
          </Link>
      ):
      null
      }
      />
      <div className="Home__BoxSection">
        {contentModule && (
          <div className="Home__BoxSection__box">
            <Heading
              className="Home__BoxSection__box__title"
              number={1}
              color="black-a80"
            >
              {contentModule.title}
            </Heading>
            <Body
              className="Home__BoxSection__box__text"
              block
              number={1}
              color="black-a80"
            >
              {contentModule.description}
            </Body>
            <div className="Home__BoxSection__box__cardsContainer">
              {contentModule.cards &&
                contentModule.cards.map((card, index) => (
                  <Link
                    key={card.id}
                    to={card.route}
                    className="Home__BoxSection__box__cardsContainer__card elevation-4"
                  >
                    <div className="Home__BoxSection__box__cardsContainer__card__image">
                      <img src={card.image.data.attributes.url || "#"} alt={card.title}/>
                    </div>
                    <div className="Home__BoxSection__box__cardsContainer__card__info">
                      <div className="Home__BoxSection__box__cardsContainer__card__info__title">
                        {card.title}
                      </div>
                      <div className="Home__BoxSection__box__cardsContainer__card__info__description ">
                        {card.description}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </HomeContext.Provider>
  );
};

export default Home;

/*
    <ReactPlayer
        width="100%"
        height="auto"
        playing
        controls
        muted
        loop
        url={video.url}
    />
*/

/*
<header className="Home__header center">
        <img src={headerImage ? headerImage.url : "#"} />
        <Heading className="Home__header__text">
          {infoModule &&
            infoModule.map((box, id) => (
              <div className="Home__BoxSection__box" key={id}>
              <div className="Home__BoxHero__box" key={id}>
                <Heading className="Home__BoxHero__title" number={3}>
                  {box.title}
                </Heading>
                <Body className="Home__BoxSection__text" block number={1}>
                  {box.description}
                </Body>
                <Link to={`/studio-design/${box.section.slug}`}>
                  <Button size="regular" color="default" type="primary">
                    {box.button}
                  </Button>
                </Link>
              </div>
            ))}
        </Heading>
      </header>

*/
