import { gql } from "@apollo/client";

function buildNavQuery(pages) {
  const queries = pages.map((page) => {
    return `
        ${page.page}{
            data{
                attributes{
                  sideBar{
                    sectionCategories(sort:"order:asc", pagination:{start:0, limit:1}){
                      data{
                        attributes{
                          slug
                          sections(sort:"order:asc", pagination:{start:0, limit:1}){
                            data{
                              attributes{
                                slug
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
        }
      `;
  });

  return gql`
    query {
      ${queries.join("\n")}
    }
  `;
}

export default buildNavQuery;
