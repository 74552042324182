import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Icon from "./Icon";
import downloadFile from "../../utils/downloadFile";
import copySvg from "../../utils/copySvg";
import { ModalContext } from "../../contexts/ModalContextProvider";

const IconCard = ({ icon }) => {
  const { setOpen, setContent, open } = useContext(ModalContext);
  return (
    <div className="iconCard" key={icon.id}>
      <div className="content">
        <div
          className="contentTop"
          onClick={() => [
            setOpen(!open),
            setContent({
              img: icon.image,
              allowDownload: true,
              isIcon:true
            }),
          ]}
        >
          <img id={`icon_${icon.id}`} src={icon.image.url} alt={icon.title} />
        </div>
        <div className="buttons">
          <Button
            type="primary"
            className="flex center"
            size="iconRounded"
            onClick={() => downloadFile(icon.imgSrc, icon.title)}
          >
            <Icon name="download" type="regular" size="small" />
          </Button>
          <Button
            type="primary"
            className="flex center"
            size="iconRounded"
            onClick={() => copySvg(`icon_${icon.id}`)}
          >
            <Icon name="copy" type="regular" size="small" />
          </Button>
        </div>
      </div>
      <div className="title">{icon.title}</div>
    </div>
  );
};

export default IconCard;
