import React, { useState } from 'react'

export const ModalContext=React.createContext()

const ModalContextProvider = ({children}) => {
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState({img:null, allowDownload:false, isIcon:false})
    
    return (
      <ModalContext.Provider value={{
          open,
          content,
          setOpen,
          setContent
      }}>
          {children}
      </ModalContext.Provider>
    )
}

export default ModalContextProvider