import React, { useContext, useEffect, useRef, useState } from "react";
import SidebarNav from "../modules/SidebarNav";
import { useQuery } from "@apollo/client";
import { MemoizeSection } from "../modules/Section";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/MenuContextProvider";
import Loading from "../modules/Loading";
import { GET_DEBUT_HEADER } from "../../queries";
import HeroHeader from "../modules/HeroHeader";

const DebutPage = ({ initialCategory, initialSection, query, queryPage, pageTitle }) => {
  const { setMenu } = useContext(MenuContext);
  const { data, loading } = useQuery(query);

  const { data:heroHeaderData } = useQuery(GET_DEBUT_HEADER);

  const { sideBar = {}, header={} } =  data && data[queryPage]? data[queryPage].data?.attributes : {}

  const { sectionCategories: { data: dataSide } = {} } = sideBar
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const headerRef = document.getElementById("header")?.clientHeight;
    const heroRef = document.getElementById("heroheader")?.clientHeight || 0;
    if (window.scrollY > (headerRef + heroRef - 10)) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  };

  const [currentPage, setCurrentPage] = useState({
    pageTitle,
    defaultSection: initialSection,
    category: initialCategory,
    section: initialSection,
    page: ''
  });

  useEffect(() => {
    if(isVisible){
      let sidebarEl = document.getElementById("sidebar");
      if(sidebarEl && sidebarEl.scrollTop){
        sidebarEl.scrollTop = 0;
      }
    }

  }, [isVisible])

  useEffect(() => {
    if (location.pathname.length > 1) {
      const paths = location.pathname.split('/');
      const pathPage = paths[1] || ''
      const pathCategory = paths[2] || ''
      const pathSection = paths[3] || '';
      setCurrentPage({ ...currentPage, category: pathCategory, section: pathSection, page: pathPage, pageTitle })
    }
  }, [location.pathname]);

  useEffect(() => {
    setMenu(dataSide)
    return () => {
      setMenu([])
    }
  }, [dataSide])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (loading) return <Loading />;
  return (
    <>
    {heroHeaderData?.debutHeader?.data && (
      <HeroHeader
        imageSrc={heroHeaderData.debutHeader.data.attributes.image?.data?.attributes.url}
        title={header?.title}
        subtitle={header?.description}
        infoPosition="Center"
      />
    )}
    <div className="Guidelines__Container Debut">
      <div className="Guidelines__Container__Layout">
        <div className={`Guidelines__Container__Layout__Sidebar ${isVisible ? 'Guidelines__Stiky' : ''}`}>
          <div id="sidebar" className={`${isVisible ? 'Guidelines__Stiky' : ''}`}>
            <SidebarNav data={dataSide} />
          </div>
        </div>
        <div id="sectionContent" className={`Guidelines__Container__Layout__Content ${isVisible ? 'Guidelines__Scrollable' : ''} Debut__body`}>
          <MemoizeSection page={currentPage} sectionsList={dataSide || []} isDebut={true} />
        </div>
      </div>
    </div>
    </>
  );
};
export default DebutPage;
