import React, { useContext, useEffect, useRef, useState } from "react";
import SidebarNav from "../modules/SidebarNav";
import { GET_GUIDELINE } from "../../queries";
import { useQuery } from "@apollo/client";
import { MemoizeSection } from "../modules/Section";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/MenuContextProvider";
import Loading from "../modules/Loading";
import { UserContext } from "../../contexts/UserContextProvider";

const INITIAL_CATEGORY = 'getting-started'
const INITIAL_SECTION = 'overview'
const EDITOR_ROLE="Design Editor"

const GuideLines = () => {
  const [viewerType, setViewerType] = useState("LIVE")
  const { setMenu } = useContext(MenuContext);
  const { data, loading, refetch } = useQuery(GET_GUIDELINE, {
    variables: { VIEWER_TYPE:viewerType },
  });
  const { sideBar = {} } = data?.guidelinesPage?.data?.attributes || {}
  const { sectionCategories: { data: dataSide } = {} } = sideBar
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useContext(UserContext);
  

  const handleScroll = () => {
    const headerRef = document.getElementById("header")?.clientHeight;
    if (window.scrollY > headerRef) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  };

  const [currentPage, setCurrentPage] = useState({
    defaultSection:INITIAL_SECTION,
    category: INITIAL_CATEGORY,
    section: INITIAL_SECTION,
    page: ''
  });

  useEffect(() => {
    if (
      user &&
      user.role &&
      user.role.name === EDITOR_ROLE
    ) {
      setViewerType("PREVIEW");
      refetch();
    }
  }, [user, refetch]);
  
  
  useEffect(() => {
    if(isVisible){
      let sidebarEl = document.getElementById("sidebar");
      if(sidebarEl && sidebarEl.scrollTop){
        sidebarEl.scrollTop = 0;
      }
    }

  }, [isVisible])
  

  useEffect(() => {
    if (location.pathname.length > 1) {
      const paths = location.pathname.split('/');
      const pathPage = paths[1] || ''
      const pathCategory = paths[2] || ''
      const pathSection = paths[3] || '';
      setCurrentPage({ ...currentPage, category: pathCategory, section: pathSection, page: pathPage })
    }
  }, [location.pathname]);

  useEffect(() => {
    setMenu(dataSide)
    return () => {
      setMenu([])
    }
  }, [dataSide])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (loading) return <Loading />;
  return (
    <div className="Guidelines__Container">
      <div className="Guidelines__Container__Layout">
        <div className={`Guidelines__Container__Layout__Sidebar ${isVisible ? 'Guidelines__Stiky' : ''}`}>
        <div id="sidebar" className={`${isVisible ? 'Guidelines__Stiky' : ''}`}>
          <SidebarNav data={dataSide} />
          </div>
        </div>
        <div id="sectionContent" className={`Guidelines__Container__Layout__Content ${isVisible ? 'Guidelines__Scrollable' : ''}`}>
          <MemoizeSection page={currentPage} sectionsList={dataSide || []} />
        </div>
      </div>
    </div>
  );
};
export default GuideLines;
