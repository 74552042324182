import { gql } from "@apollo/client";
export const SEARCH_ICONS = gql`
  query SearchIcon($query: String!) {
    search(query: $query) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
                name
                url
                name
                size
                width
                height
                ext
              }
            }
          }
        }
      }
    }
  }
`;
