//https://dev-api.studiodesign.disneystudioexp.com/api/icon-files?pagination[page]=4&pagination[pageSize]=100&populate[0]=icon_category&filters[icon_category][id][$eq]=2&sort[id]=asc

import { gql } from "@apollo/client";
export const GET_ICONS = gql`
  query ($ICONS_CATEGORY_SLUG: String!, $PAGE: Int!, $PAGE_SIZE: Int!) {
    iconFiles(
      sort: "title:asc"
      filters: { icon_category: { slug: { eq: $ICONS_CATEGORY_SLUG } } }
      pagination: { page: $PAGE, pageSize: $PAGE_SIZE }
    ) {
      data {
        id
        attributes {
          title
          image {
            data {
              attributes {
                url
                name
                url
                name
                size
                width
                height
                ext
              }
            }
          }
          icon_category {
            data {
              id
              attributes {
                categoryName
              }
            }
          }
          icon_collection {
            data {
              id
              attributes {
                collectionName
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;
