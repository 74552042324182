import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import Icon from "../modules/Icon";
import IconButton from "../modules/IconButton";
import MenuSurface from "../modules/MenuSurface";
import { MenuContext } from "../../contexts/MenuContextProvider";

const MobileMenu = ({ isOpen, setOpen, links = [] }) => {
  const location = useLocation();
  const { menu } = useContext(MenuContext);

  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    if (location.pathname.length > 1) {
      const paths = location.pathname.split("/");
      const path = paths[1];
      setActiveItem(path);
    }
    setOpen(false);
    document.body.classList.remove("no-scroll");
  }, [location.pathname]);

  return (
    <div className="MobileMenu">
      <IconButton
        type="light"
        name={isOpen ? "close" : "density-comfy"}
        onClick={() => setOpen(!isOpen)}
        alt="menu_button"
      />
      {isOpen && (
        <div
          className={`NavBlock ${
            activeItem === "guidelines" && "librariesHeight"
          }`}
        >
          <ul>
            {links.map((page) => {
              return !page.options ? (
                <li key={page.label}>
                  <div className="linkWrapper">
                    <Link to={`${page.targetUrl}`}>{page.label || page.title}</Link>
                  </div>
                  {page.targetUrl.split("/")[1] === activeItem && (
                    <ul>
                      {menu &&
                        menu.map((category) => {
                          return (
                            <li key={category.slug}>
                              {category.attributes.title}
                              <Sections
                                sections={category.attributes.sections.data}
                                category={category.attributes.slug}
                                page={activeItem}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  )}
                </li>
              ) : (
                <li key={page.label}>
                  <div className="linkWrapper">
                    <div className="librariesMenu">
                      {/*  <Link to={`${page.targetUrl}`}>{page.label}</Link> */}
                      <MenuSurface options={page.options}>
                        Libraries <Icon name="caret-down" />
                      </MenuSurface>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

const Sections = ({ sections, category, page }) => {
  return (
    <ul>
      {sections.map((section) => {
        return (
          <li key={section.id}>
            <Link to={`/${page}/${category}/${section.attributes.slug}`}>
              <div>{section.attributes.title}{section.attributes?.isUnderConstruction &&  <span alt="Under construction" title="Under construction">🚧</span>}</div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSearch: PropTypes.func,
};

export default MobileMenu;
