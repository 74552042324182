import React, { useState } from 'react'

export const SearchContext=React.createContext()

const SearchContextProvider = ({children}) => {
    const [searchText, setSearchText] = useState(null)
    
    return (
      <SearchContext.Provider value={{
        searchText,
        setSearchText
      }}>
          {children}
      </SearchContext.Provider>
    )
}

export default SearchContextProvider