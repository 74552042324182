import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Section from '../modules/Section';
import SideNav from '../layout/SideNav';

const StudioDesign = props => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('introduction');

    useEffect(() => {
        if (location.pathname.length > 1) {
            const paths = location.pathname.split('/');
            const path = paths[2];
            setCurrentPage(path);
        }
    }, [location.pathname]);

    return (
        <div className='Content'>
            <Helmet><title>Studio Design</title></Helmet>
            <SideNav setPage={setCurrentPage} />
            <Section slug={currentPage} />

        </div>
    );
};

StudioDesign.propTypes = {

};

export default StudioDesign;
