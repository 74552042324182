import React from "react";
import UserContextProvider from "../contexts/UserContextProvider";
import WidthContextProvider from "../contexts/WidthContextProvider";
import MenuContextProvider from "../contexts/MenuContextProvider";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import "../styles/main.scss";
import GraphiqlEditor from "./GraphiqlEditor";
import Layout from "./layout/Layout";
import DebutLayout from "./layout/DebutLayout";
import { debutRoutes, routes  } from "../routes";
import ModalContextProvider from "../contexts/ModalContextProvider";
import SearchContextProvider from "../contexts/SearchContextProvider";
import Home from "./pages/Home";
import DebutHome from "./pages/DebutHome";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <WidthContextProvider>
          <MenuContextProvider>
            <ModalContextProvider>
              <SearchContextProvider>
                  
              <Routes>
                  <Route path="/graphiql" element={GraphiqlEditor} />
                  <Route element={<Layout/>} >
                  {routes.map((route,index)=>(

                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                    />
                  ))}
                    </Route>
                 <Route element={<DebutLayout/>} >
                 {debutRoutes.map((route,index)=>{
                  const { path, Component, ...routeProps } = route;
                  return(
                    <Route
                    key={index}
                    path={path}
                    element={<Component {...routeProps}/>}
                    />
                    )
                 })}
                 </Route>
                 <Route
                    path="*"
                    element={<NotFound/>}
                />
                </Routes>
              </SearchContextProvider>
            </ModalContextProvider>
          </MenuContextProvider>
        </WidthContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
