import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        role {
          name
        }
      }
    }
  }
`;

export const ME = gql`
  {
    user: me {
      id
      email
      role {
        name
      }
    }
  }
`;
