import React from "react";
import Button from "../modules/Button";
import Icon from "../modules/Icon";
import MenuSurface from "../modules/MenuSurface";
import IconCard from "../modules/IconCard";
import downloadIconsPackage from "../../utils/downloadPackage";
import { Helmet } from "react-helmet-async";
import useIcons from "../../hooks/useIcons";

const Libraries = () => {
  const {
    displayIcons,
    iconsCategory,
    iconsCollections,
    menuSurfaceOptions,
    sort,
    filterByCollection,
    downloadProgress,
    setDownloadProgress,
    handleIconsSearch,
  } = useIcons();

  return (
    <div className="Libraries">
      <Helmet className="helmet">
        <title>
          {!iconsCategory.name
            ? `Libraries`
            : `${iconsCategory.name} - Libraries`}
        </title>
      </Helmet>
      <div className="Libraries__Container">
        <div className="Libraries__Container__Searchbar">
          <div className="Libraries__Container__Searchbar__InputSearch">
            <input
              type="text"
              placeholder="Search icons"
              onChange={handleIconsSearch}
            />
            <Button size="regular" color="default" type="primary">
              Search
            </Button>
          </div>
        </div>
        <div className="Libraries__Container__Toolbar">
          <div className="Libraries__Container__Toolbar__Options">
            <div className="Libraries__Container__Toolbar__Options__GroupOne">
              <div className="Libraries__Container__Toolbar__Options__GroupOne__TotalIcons">
                {`${displayIcons.length} Icons`}
              </div>
              <div className="Libraries__Container__Toolbar__Options__GroupOne__SortMobile">
                {iconsCollections?.length > 1 && (
                  <MenuSurface options={iconsCollections}>
                    {filterByCollection === "99"
                      ? `Filter by collection: All`
                      : `Filter by collection: ${
                          iconsCollections.find(
                            (collection) => collection.id === filterByCollection
                          ).name
                        }`}
                    <Icon name="caret-down" />
                  </MenuSurface>
                )}
                <MenuSurface options={menuSurfaceOptions}>
                  {sort !== "desc"
                    ? "Sort by: Title A-Z "
                    : "Sort by: Title Z-A "}
                  <Icon name="caret-down" />
                </MenuSurface>
              </div>
            </div>
            <div className="Libraries__Container__Toolbar__Options__GroupTwo">
              {!downloadProgress && (
                <Button
                  type="surface"
                  icon="arrow-circle-down"
                  onClick={() =>
                    filterByCollection === "99"
                      ? downloadIconsPackage(
                          0,
                          iconsCategory.id,
                          iconsCategory.name,
                          setDownloadProgress
                        )
                      : downloadIconsPackage(
                          1,
                          filterByCollection,
                          iconsCollections.find(
                            (collection) => collection.id === filterByCollection
                          ).name,
                          setDownloadProgress
                        )
                  }
                >
                  {filterByCollection === "99"
                    ? `Download all icons`
                    : `Download ${
                        iconsCollections.find(
                          (collection) => collection.id === filterByCollection
                        ).name
                      } collection`}
                </Button>
              )}
              {downloadProgress && (
                <div>{`Preparing your download ${downloadProgress}%`}</div>
              )}
              <div className="Libraries__Container__Toolbar__Options__GroupTwo__Sort">
                {iconsCollections?.length > 1 && (
                  <MenuSurface options={iconsCollections}>
                    {filterByCollection === "99"
                      ? `Filter by collection: All`
                      : `Filter by collection: ${
                          iconsCollections.find(
                            (collection) => collection.id === filterByCollection
                          ).name
                        }`}
                    <Icon name="caret-down" />
                  </MenuSurface>
                )}
                <MenuSurface options={menuSurfaceOptions}>
                  {sort && sort !== "desc"
                    ? "Sort by: Title A-Z"
                    : "Sort by: Title Z-A"}
                  <Icon name="caret-down" />
                </MenuSurface>
              </div>
            </div>
          </div>
        </div>
        <div className="Libraries__Container__Icons">
          {displayIcons?.map(
            (icon, index) =>
              !displayIcons
                .slice(0, index)
                .some((prevItem) => prevItem.id === icon.id) && (
                <IconCard icon={icon} key={icon.id} />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default Libraries;
