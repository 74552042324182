import { gql } from '@apollo/client';

export const GET_SECTION = gql`
query singleSection($VIEWER_TYPE: PublicationState,$CATEGORY_SLUG: String!, $SECTION_SLUG: String!) {
  sectionCategories(publicationState:$VIEWER_TYPE,filters:{slug:{eq:$CATEGORY_SLUG}}){
    data{
      attributes{
        sections(publicationState:$VIEWER_TYPE,filters: {slug: {eq:$SECTION_SLUG}}){
          data{
            attributes{
              publishedAt
              title
              isUnderConstruction
              updatedAt
              content{
                ...on ComponentContentDescription{
                  id
                  description
                  __typename
                }
                ...on ComponentContentImageToDownload{
                  __typename
                  image{
                    columns
                    caption
                    image{
                      data{
                        attributes{
                          url
                          name
                          url
                          name
                          size
                          width
                          height
                          ext
                        }
                      }
                    }
                  }
                }
                ...on ComponentContentTip{
                  id
                  note
                  __typename
                }
                ...on ComponentContentImageSlugContainer{
                  id
                  __typename
                  imageButton{
                    id
                    sub_section{
                      data{
                        attributes{
                          slug
                        }
                      }
                    }
                    image{
                      data{
                        attributes{
                          url
                        }
                      }
                    }
                  }
                }
                ...on ComponentContentContact{
                  email
                  imageContact:image{
                      data{
                        attributes{
                          url
                        }
                      }
                    }
                  __typename
                }
              }
              slug
              subsections(publicationState:$VIEWER_TYPE, sort:"order:asc", pagination:{start:0, limit:25}){
                data{
                  attributes{
                    publishedAt
                    title
                    order
                    slug
                    updatedAt
                    content{
                      ...on ComponentContentDescription{
                        __typename
                        description
                      }
                      ...on ComponentContentTip{
                        __typename
                        note
                      }
                      ...on ComponentContentImageButtonContainer{
                        __typename
                        file{
                          imageColumns
                          url
                          image{
                            data{
                              attributes{
                                url
                              }
                            }
                          }
                          file{
                            data{
                              attributes{
                                url
                                name
                              }
                            }
                          }
                        }
                      }
                      ...on ComponentContentImageToDownload{
                        __typename
                        image{
                          columns
                          caption
                          image{
                            data{
                              attributes{
                                url
                                name
                                url
                                name
                                size
                                width
                                height
                                ext
                              }
                            }
                          }
                        }
                      }
                      ...on ComponentContentColorFile{
                        __typename
                        HTMLComponent
                        fileToDownload{
                          data{
                            attributes{
                              url
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
  `