import { useQuery } from '@apollo/client';
import React from 'react'
import { Helmet } from "react-helmet-async";
import { GET_DEBUT_HOME } from '../../queries';
import Loading from '../modules/Loading';
import { Heading, Body } from "../modules/Typography";
import { Link } from "react-router-dom";
import HeroHeader from '../modules/HeroHeader';
import Button from '../modules/Button';

const DebutHome = () => {
    const { data, loading, error } = useQuery(GET_DEBUT_HOME);
    const { header = {}, cardModule = {} } = data?.debutHomePage?.data?.attributes || {};
    const {
      title,
      description,
      image: { data: { attributes: { url: imageUrl = "#" } = {} } = {} } = {},
      sectionButton,
    } = header;
    const { moduleTitle = '', moduleDescription = '', moduleCard = '' } = cardModule || {};
    const contentModule = {
      title: moduleTitle,
      description: moduleDescription,
      cards: moduleCard,
    };
  
    if (loading) return <Loading />;
    return (
      <>
        <div className='DebutHome'>
          <Helmet>
            <title>Debut Guidelines</title>
          </Helmet>
          <HeroHeader
            title={title}
            subtitle={description}
            imageSrc={imageUrl}
            isSecundary={false}
            customHeader={true}
            button={ sectionButton?.route ?(
                <Link to={sectionButton.route}>
                  <Button size="regular" color="default" type="primary">
                    {sectionButton.label}
                  </Button>
                </Link>
            ):
            null
            }
          />
        </div>
        {contentModule.title && (
          <div className='Home__BoxSection__box'>
            <Heading className='Home__BoxSection__box__title' number={1} color='black-a80'>
              {contentModule.title}
            </Heading>
            <Body className='Home__BoxSection__box__text' block number={1} color='black-a80'>
              {contentModule.description}
            </Body>
            <div className='Home__BoxSection__box__cardsContainer'>
              {contentModule.cards &&
                contentModule.cards.map((card, index) => (
                  <Link
                    key={card.id}
                    to={card.route}
                    className='Home__BoxSection__box__cardsContainer__card elevation-4'
                  >
                    <div className='Home__BoxSection__box__cardsContainer__card__image'>
                      <img src={card.image?.data.attributes.url || '#'} alt={card.title} />
                    </div>
                    <div className='Home__BoxSection__box__cardsContainer__card__info'>
                      <div className='Home__BoxSection__box__cardsContainer__card__info__title'>
                        {card.title}
                      </div>
                      <div className='Home__BoxSection__box__cardsContainer__card__info__description'>
                        {card.description}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </>
    );
  };

export default DebutHome