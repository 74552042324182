import { gql } from '@apollo/client';

export const GET_DEBUT_HOME = gql`
query debutHomePageData {
  debutHomePage {
    data {
      attributes {
        cardModule {
          moduleTitle
          moduleDescription
          moduleCard {
            id
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
            route
          }
        }
        header {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
          sectionButton {
            label
            route
          }
        }
      }
    }
  }
}
`;
