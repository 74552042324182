import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import propTypes from "prop-types";

const HeroHeader = ({
  imageSrc,
  title,
  subtitle,
  button,
  infoPosition = "Left",
  customHeader = false,
}) => {
  
  return (
    <div
      className="HeroHeader"
      id='heroheader'
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}
    >
      <div className={`HeroHeader__infoContainer position${infoPosition} ${!customHeader?'HeroHeader__shortHeader':''}`}>
        <div
          className={`HeroHeader__infoContainer__info${customHeader ? "" : "Default"
            }`}
        >
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
          {button && <div className="button">{button}</div>}
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;

HeroHeader.propTypes = {
  // Hero image
  imageSrc: PropTypes.string.isRequired,

  // Title of the hero
  title: PropTypes.string.isRequired,

  // className to pass down to tag
  subtitle: PropTypes.string,

  // function to active on click
  button: PropTypes.node,

  // whether or not the button is disabled
  contentPosition: PropTypes.oneOf([
    "Center",
    "TopLeft",
    "Top",
    "TopRight",
    "Right",
    "BottomRight",
    "Bottom",
    "BottomLeft",
    "Left",
  ]),
};
