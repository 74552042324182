import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const SidebarNav = ({ data: pageData }) => {
  const [pageRoute, setPageRoute] = useState("");
  const [currentSection, setCurrentSection] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.length > 1) {
      const paths = location.pathname.split("/");
      const pathPage = paths[1];
      const pageSection = paths[3];
      setCurrentSection(pageSection);
      setPageRoute(pathPage);
    }
  }, [location.pathname]);

  return (
    <nav className="Sidebarnav">
      {pageData &&
        pageData.map((page) => {
          const {
            attributes: {
              title: categoryTitle,
              publishedAt: published,
              slug: categorySlug,
              sections: { data: sectionList = [] } = {},
            } = {},
          } = page;
          return (
            <div key={page.id} className="Sidebarnav__Container">
              <Link
                to={`/${pageRoute}/${categorySlug}/${sectionList[0]?.attributes.slug}`}
              >
                <span className={!published && 'draft' }>
                  {categoryTitle} 
                  {!published && (
                    <span alt="Draft" title="Draft">
                      ✏️
                    </span>
                  )}
                 </span>
              </Link>
              <Sections
                sections={page.attributes?.sections?.data}
                pageRoute={pageRoute}
                categorySlug={categorySlug}
                currentSection={currentSection}
              />
            </div>
          );
        })}
    </nav>
  );
};

const Sections = ({ sections, pageRoute, categorySlug, currentSection }) => {
  return (
    <ul>
      {sections &&
        sections.map((section) => {
          const {
            attributes: {
              title: sectionTitle,
              isUnderConstruction,
              publishedAt: published,
              slug: slugSection,
              subsections: { data: subsectionList = [] } = {},
            } = {},
          } = section;
          const link = `/${pageRoute}/${categorySlug}/${slugSection}`;
          const isLinkActive = link === location.pathname + location.hash;
          return (
            <li key={section.id}>
              <Link
                className={isLinkActive ? "Sidebarnav__Active" : ""}
                to={link}
              >
                <div className={!isUnderConstruction && !published && 'draft' }>
                  {sectionTitle}
                  {isUnderConstruction && (
                    <span alt="Under construction" title="Under construction">
                      🚧
                    </span>
                  )}
                  {!isUnderConstruction && !published && (
                    <span alt="Draft" title="Draft">
                      ✏️
                    </span>
                  )}
                </div>
              </Link>
              {section.attributes?.slug === currentSection &&
                section.attributes.subsections.data.length > 0 && (
                  <Subsections
                    sectionLink={link}
                    subsections={subsectionList}
                  />
                )}
            </li>
          );
        })}
    </ul>
  );
};

const Subsections = ({ subsections, sectionLink }) => {
  return (
    <ul>
      {subsections &&
        subsections.map((subsection) => {
          const {
            attributes: {
              title: subsectionTitle,
              publishedAt: published,
              slug: slugSubsection = "",
            } = {},
          } = ({} = subsection);
          const subsectionLink = `${sectionLink}#${slugSubsection}`;
          const isLinkActive =
            subsectionLink === location.pathname + location.hash;
          return (
            <li
              key={subsection.id}
              className={isLinkActive ? "Sidebarnav__Active" : ""}
            >
              <HashLink className={!published && 'draft' } key={slugSubsection} to={`#${slugSubsection}`}>
                {subsectionTitle} 
                {!published && (
                    <span alt="Draft" title="Draft">
                      ✏️
                    </span>
                )}
              </HashLink>
            </li>
          );
        })}
    </ul>
  );
};

export default SidebarNav;
