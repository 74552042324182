import Home from "./components/pages/Home";
import StudioDesign from "./components/pages/StudioDesign";
import DebutPage from "./components/pages/DebutPage";
import Guidelines from "./components/pages/GuideLines";
import About from "./components/pages/About";
import Libraries from "./components/pages/Libraries";
import DebutHome from "./components/pages/DebutHome";
import {
  GET_DEBUT_ABOUT,
  GET_DEBUT_ACCESS,
  GET_DEBUT_APPDOCUMENTATION,
  GET_DEBUT_IMAGEGUIDELINES,
  GET_DEBUT_VIDEODELIVERY,
  GET_DEBUT_WATERMARKING,
  GET_DEBUT_WORKING,
} from "./queries";

export const routes = [
  { path: "/", component: Home },
  { path: "/guidelines/:category/:section", component: Guidelines },
  { path: "/about/:category/:section", component: About },
  { path: "/libraries/:slug", component: Libraries },
  { path: "/studio-design/introduction", component: StudioDesign },
  { path: "/studio-design/:slug", component: StudioDesign },
];

export const debutRoutes = [
  { path: "/debut", Component: DebutHome },
  {
    path: "/aboutdebut/:category/:section",
    Component: DebutPage,
    pageTitle: "What is Debut?",
    initialCategory: "what-is-debut",
    initialSection: "about-overview",
    query: GET_DEBUT_ABOUT,
    queryPage: "aboutDebutPage",
  },
  {
    path: "/accessandsupport/:category/:section",
    Component: DebutPage,
    pageTitle: "Access & Support",
    initialCategory: "access-and-viewing-instructions",
    initialSection: "how-to-register-an-account",
    query: GET_DEBUT_ACCESS,
    queryPage: "accessAndSupportPage",
  },
  {
    path: "/workingwithdebut/:category/:section",
    Component: DebutPage,
    pageTitle: "Working with Debut",
    initialCategory: "Image Guidelines",
    initialSection: "event-request-form",
    query: GET_DEBUT_WORKING,
    queryPage: "workingWithDebutPage",
  },
  {
    path: "/imageguidelines/:category/:section",
    Component: DebutPage,
    pageTitle: "Image Guidelines",
    initialCategory: "image-guidelines",
    initialSection: "imagery-checklist",
    query: GET_DEBUT_IMAGEGUIDELINES,
    queryPage: "imageGuidelinesPage",
  },
  {
    path: "/watermarking/:category/:section",
    Component: DebutPage,
    pageTitle: "Watermarking",
    initialCategory: "watermarking",
    initialSection: "overview-of-watermarking",
    query: GET_DEBUT_WATERMARKING,
    queryPage: "watermarkingPage",
  },
  {
    path: "/videodelivery/:category/:section",
    Component: DebutPage,
    pageTitle: "Video Delivery Guidelines",
    initialCategory: "video-delivery-guidelines",
    initialSection: "debut-specs",
    query: GET_DEBUT_VIDEODELIVERY,
    queryPage: "videoDeliveryGuidelinesPage",
  },
  {
    path: "/appdocumentation/:category/:section",
    Component: DebutPage,
    pageTitle: "App Documentation",
    initialCategory: "appdoc-admin",
    initialSection: "appdoc-onboarding",
    query: GET_DEBUT_APPDOCUMENTATION,
    queryPage: "appDocumentationPage",
  },
];
