import React, { useEffect, useState } from 'react'
import arrow from '../../images/content/up-arrow.svg'

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const headerRef = document.getElementById("header")?.clientHeight || 500;
    if (window.scrollY > headerRef - 30) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Desplazamiento suave
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <>
    {isVisible && (
      <span className="ScrollButton" onClick={scrollToTop}>
        <img src={arrow} alt="up" />
      </span>
    )}
    </>
   
  )
}

export default ScrollButton