import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { HashLink as LinkHash } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { GET_NAV } from '../../queries';
import { Body } from '../modules/Typography';
import Loading from '../modules/Loading';

const SideNav = props => {
    const location = useLocation();
    const { data, loading } = useQuery(GET_NAV);
    const { isMobile } = useContext(WidthContext);
    const [activeItem, setActiveItem] = useState('');
    const sortedSections = data ? [...data.sideNav.sectionContainer.sections].sort(function (a, b) {
        return new Date(a.navOrder) - new Date(b.navOrder);
    }) : [];

    useEffect(() => {
        if (location.pathname.length > 1) {
            const paths = location.pathname.split('/');
            const path = paths[2];
            setActiveItem(path);
        }
    }, [location.pathname]);

    if (loading) return <Loading />;
    return (
        <div className='SideNav'>
            {!isMobile &&
            <>
                <Body number={6} className='SideNav__title'>Studio Design</Body>
                {sortedSections.map((item, id) => (
                    <div onClick={props.setPage(activeItem)} className={`SideNav__items ${(item.slug === activeItem) ? 'active' : ''}`} key={id}>
                        <Link to={`/studio-design/${item.slug}`}>
                            <Body number={2}>{item.title}</Body>
                        </Link>
                        {item.subsections.map(section => (
                            <LinkHash to={`/studio-design/${item.slug}#${section.slug}`}>
                                <Body className={`SideNav__title__subsection ${(item.slug === activeItem) ? '' : 'hide'}`} number={2}>{section.title}</Body>
                            </LinkHash>
                        ))}

                    </div>
                ))}
            </>}
        </div>
    );
};

SideNav.propTypes = {
    setPage: PropTypes.function

};

export default SideNav;
