function findCategoryAndCloseSections(sectionsList, slugCategory, slugSection) {
  const sectionCategories = sectionsList;

  let prevCategory = null;
  let prevSection = null;
  let nextCategory = null;
  let nextSection = null;

  for (let i = 0; i < sectionCategories.length; i++) {
    const category = sectionCategories[i];
    const sections = category.attributes.sections.data;

    for (let j = 0; j < sections.length; j++) {
      const section = sections[j];

      if (section.attributes.slug === slugSection) {
        if (category.attributes.slug === slugCategory) {
          if (j > 0) {
            prevSection = {
              slug: sections[j - 1].attributes.slug,
              title: sections[j - 1].attributes.title,
              underConstruction:sections[j - 1].attributes.isUnderConstruction
            };
            prevCategory = {
              slug: category.attributes.slug,
              title: category.attributes.title,
            };
          } else if (i > 0) {
            const lastSectionOfPreviousCategory =
              sectionCategories[i - 1].attributes.sections.data[
              sectionCategories[i - 1].attributes.sections.data.length - 1
              ];
            if(lastSectionOfPreviousCategory){
              prevSection = {
                slug: lastSectionOfPreviousCategory.attributes.slug,
                title: lastSectionOfPreviousCategory.attributes.title,
                underConstruction:lastSectionOfPreviousCategory.attributes.isUnderConstruction
              };
              prevCategory = {
                slug: sectionCategories[i - 1].attributes.slug,
                title: sectionCategories[i - 1].attributes.title,
              };
            }
            
          }

          if (j < sections.length - 1) {
            nextSection = {
              slug: sections[j + 1].attributes.slug,
              title: sections[j + 1].attributes.title,
              underConstruction:sections[j + 1].attributes.isUnderConstruction
            };
            nextCategory = {
              slug: category.attributes.slug,
              title: category.attributes.title,
            };
          } else if (i < sectionCategories.length - 1) {
            const firstSectionOfNextCategory =
              sectionCategories[i + 1].attributes.sections.data[0];
            if(firstSectionOfNextCategory){
              nextSection = {
                slug: firstSectionOfNextCategory.attributes.slug,
                title: firstSectionOfNextCategory.attributes.title,
                underConstruction:firstSectionOfNextCategory.attributes.isUnderConstruction
              };
              nextCategory = {
                slug: sectionCategories[i + 1].attributes.slug,
                title: sectionCategories[i + 1].attributes.title,
              };
            }
          }

          return {
            prev: { category: prevCategory, section: prevSection },
            next: { category: nextCategory, section: nextSection },
          };
        } else {
          return {};
        }
      }
    }
  }

  return {};
}

export default findCategoryAndCloseSections