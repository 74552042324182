import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import Subsection from "./Subsection";
import { HashLink } from "react-router-hash-link";
import BottomNav from "../layout/BottomNav";
import { useEffect } from "react";
import findCategoryAndCloseSections from "../../utils/findeCloseSection";
import { GET_SECTION } from "../../queries/section";
import { Heading } from "./Typography";
import { Helmet } from "react-helmet-async";
import { capitalize } from "../../utils/findSectionWithKeyword";
import { LoadingIcon } from "./Loading";
import { ModalContext } from "../../contexts/ModalContextProvider";
import ParseMarkdown from "./MarkdownParser";
import { SearchContext } from "../../contexts/SearchContextProvider";
import downloadFile from "../../utils/downloadFile";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import getLastModficiation from "../../utils/getLastModification";
import { UserContext } from "../../contexts/UserContextProvider";

const EDITOR_ROLE="Design Editor"

const Section = ({ page, sectionsList, isDebut }) => {
  const {
    category,
    section,
    defaultSection,
    page: currenPage,
    pageTitle,
  } = page;
  const [viewerType, setViewerType] = useState("LIVE")
  const { data, loading, refetch } = useQuery(GET_SECTION, {
    variables: { VIEWER_TYPE: viewerType, CATEGORY_SLUG: category, SECTION_SLUG: section },
  });
  const {
    content: sectionContent = [],
    title: sectionTitle,
    publishedAt: published,
    isUnderConstruction,
    subsections = [],
  } = data?.sectionCategories?.data[0]?.attributes?.sections?.data[0]
    ?.attributes || {};
  const [prevSection, setPrevSection] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const { open, setOpen, setContent } = useContext(ModalContext);
  const { setSearchText, searchText } = useContext(SearchContext);
  const [lastModification, setLastModification] = useState(null)
  const { user } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    if (
      user &&
      user.role &&
      user.role.name === EDITOR_ROLE
    ) {
      setViewerType("PREVIEW");
      refetch();
    }
  }, [user, refetch]);

  useEffect(() => {
    if (searchText && data) {
      setTimeout(() => {
        processElements();
      }, 1100);
    }
    const processElements = () => {
      const markdownParserElements =
        document.querySelectorAll(".markdown-parser"); 
      if (markdownParserElements) {
        markdownParserElements.forEach((markdownParser) => {
          let lastMatchingElement = null; 

          const elements = markdownParser.querySelectorAll("*"); 
          elements.forEach((element) => {
            const elementText = element.textContent?.toLowerCase().replace(/\n/g, "");
            const searchTextLower = searchText?.replace(/\n/g, "").toLowerCase();

              if (elementText && (searchTextLower.length <= 20 ? elementText === searchTextLower : elementText.includes(searchTextLower))) {
                lastMatchingElement = element;
              }
          });
          if (lastMatchingElement) {
            lastMatchingElement.classList.add("highlight");
            lastMatchingElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });

            setSearchText(null);
          }
        });
      }
    };
  }, [data, searchText]);

  useEffect(() => {
    const { prev, next } = findCategoryAndCloseSections(
      sectionsList,
      category,
      section
    );
    setPrevSection(prev);
    setNextSection(next);
  }, [page]);

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [open]);

  useEffect(() => {
    if(data && data.sectionCategories.data[0] && data.sectionCategories.data[0]?.attributes.sections.data[0]){
      setLastModification(getLastModficiation(data.sectionCategories.data[0]?.attributes.sections.data[0]?.attributes))
    }
    if (data && (defaultSection !== section || location.hash) ) {
      const hash = location.hash?.replace('#','');
      if(hash){
        const element=document.getElementById(hash)
        if(element){
          setTimeout(() => {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 1000);
        }
      }else{
        const headerRef =
        document.getElementById("header")?.clientHeight
        const heroRef = document.getElementById("heroheader")?.clientHeight || 0;
        setTimeout(() => {
          window.scrollTo({ top: heroRef + headerRef || 500, left: 0, behavior: "instant" });
        }, 30);
      }
    }
  }, [data]);

  if (loading) return <LoadingIcon />;

  return (
    <main id="section" className="Section">
      <Helmet className="helmet">
        <title>
          {capitalize(
            `${sectionTitle?sectionTitle:'The Walt Disney Studios'} - ${pageTitle || currenPage} - ${
              isDebut ? "Debut Guidelines" : "Studio Design Platform"
            }`
          )}
        </title>
      </Helmet>
      <Heading className="Section__title" number={3}>
        {sectionTitle} 
        {isUnderConstruction && <span alt="Under construction" title="Under construction">🚧</span>}
        {!isUnderConstruction && !published && <span alt="Draft" title="Draft">✏️</span>}
      </Heading>
      {sectionContent.length > 0 || subsections.data?.length > 0?
      <div className="Section__body">
        {sectionContent.map((content) => (
          <div key={content.id}>
            {content.__typename === "ComponentContentDescription" && (
              <div className="Section__body__richContent">
                <ParseMarkdown markdownText={content.description} />
              </div>
            )}
            {content.__typename === "ComponentContentImageToDownload" && (
              <div className="Subsection__body__fileContainer">
                {content.image?.map((image) => {
                  const img = image?.image?.data?.attributes || {};
                  return (
                    <div
                      key={img.url}
                      href={img.url}
                      download={img.name}
                      className={`Subsection__body__fileContainer__column Subsection__body__fileContainer__column${
                        image.columns || "12"
                      }`}
                    >
                      <div
                        className="Subsection__body__fileContainer__column__actionButton"
                        onClick={() => downloadFile(img.url, img.name)}
                      />
                      <LazyLoadImage onClick={() => [setOpen(!open), setContent({img, allowDownload:true})]}
                        className={`Subsection__body__fileContainer__column__download ${
                          img.name?.toLowerCase()?.includes("white")
                            ? "Subsection__black"
                            : ""
                        } ${
                          /black|white|primary/.test(img.name?.toLowerCase())
                            ? "Subsection__border"
                            : ""
                        }`}
                        src={img.url || "#"}
                      alt={image.name || "Download image"}
                      effect="blur"
                    />
                      {image.caption && (
                        <p
                          className={`Subsection__body__fileContainer__column__download__caption${
                            image.columns === 12 ? " Subsection__center" : ""
                          }`}
                        >
                          {image.caption}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {content.__typename === "ComponentContentTip" && (
              <div className="Section__body__note">
                <ParseMarkdown markdownText={content.note} />
              </div>
            )}
            {content.__typename === "ComponentContentImageSlugContainer" && (
              <div>
                <ul className="Section__body__contentBlock">
                  {content.imageButton?.map((resource) => {
                    return (
                      <li
                        key={resource.id}
                        className="Section__body__contentBlock__card"
                      >
                        <HashLink
                          to={`#${resource.sub_section?.data?.attributes?.slug}`}
                        >
                          <img
                            src={
                              `${resource.image?.data?.attributes?.url}` || "#"
                            }
                            alt=""
                            className="Section__body__contentBlock__imageGrid_image"
                          />
                        </HashLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {content.__typename === "ComponentContentContact" && (
              <a href={`mailto: ${content.email}` || ""} target="_blank" aria-label="Send email">
                <img
                  src={`${content.imageContact?.data?.attributes?.url}` || "#"}
                  alt="email contact"
                  style={{ maxWidth: "300px" }}
                />
              </a>
            )}
          </div>
        ))}

        {sectionContent.length > 0 && subsections.data.length > 0 && <hr />}

        {subsections.data?.map((subsection, index) => {
          const { slug: subsectionSlug } = subsection.attributes || {};
          return (
            <div key={subsectionSlug}>
              <Subsection data={subsection} />
              {index !== subsections.data.length - 1 && <hr />}
            </div>
          );
        })}
        
        <BottomNav prevSection={prevSection} nextSection={nextSection} />
        <span className="Section__date">Last modified on 
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className="tw-flex tw-items-center tw-justify-center tw-fill-current" name="IconChartPie12"><g fill="none"><g clip-path="url(#ChartPie12)"><path fill="currentColor" fillRule="evenodd" d="M10.975 6.5a5 5 0 1 1-5.487-5.474V6.5h5.487Zm0-1H6.488V1.024A5.002 5.002 0 0 1 10.975 5.5ZM12 6A6 6 0 1 1 0 6a6 6 0 0 1 12 0Z" clipRule="evenodd"></path></g><defs><clipPath id="ChartPie12"><path fill="#fff" d="M0 0h12v12H0z"></path></clipPath></defs></g></svg>
          { lastModification}
        </span>
      </div>
      :
      <h1 className="Section__title text-center">No content found!</h1>
      }
    </main>
  );
};

export const MemoizeSection = React.memo(Section);
