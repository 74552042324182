import { isObject } from "lodash";
import convertRichTextToPlainText from "./richTextToPlainText";

export function capitalize(sentence) {
  const words = sentence.split(' ');
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word; 
  });
  const capitalizedSentence = capitalizedWords.join(' ');

  return capitalizedSentence;
}

function findSectionsWithKeyword(data, keyword) {
    const results = [];

    function processSectionCategory(category, pageName) {
      const categorySlug = category.attributes.slug;
      category.attributes.sections?.data.forEach((section) => {
        const sectionTitle = capitalize(section.attributes.title.toLowerCase());

        if (
          sectionTitle && sectionTitle.includes(keyword)
        ) {
          results.push({
            page:pageName,
            url: `/${pageName}/${categorySlug}/${section.attributes.slug}`,
            section: sectionTitle
          });
        }
        section.attributes.content?.forEach((content) => {
          const sectionContent = convertRichTextToPlainText(content.description || content.note || '', keyword);
          if (sectionContent && sectionContent.includes(keyword)) {
            results.push({
              page:pageName,
              url: `/${pageName}/${categorySlug}/${section.attributes.slug}`,
              section: sectionTitle,
              sectionContent
            });
          }
        })

        section.attributes.subsections.data.forEach((subsection) => {
          const subsectionSlug = subsection.attributes.slug;
          const subsectionTitle= subsection.attributes.title;

          if (
            subsectionTitle.includes(keyword)
            
          ) {
            results.push({
              page:pageName,
              url: `/${pageName}/${categorySlug}/${section.attributes.slug}#${subsectionSlug}`,
              section: sectionTitle,
              subsection: subsectionTitle,
            });
          }

          subsection.attributes.content?.forEach(content=>{
            const subsectionContent=convertRichTextToPlainText(content.description || content.note || '',keyword);
            if(subsectionContent && subsectionContent.includes(keyword)){
              results.push({
                page:pageName,
                url: `/${pageName}/${categorySlug}/${section.attributes.slug}#${subsectionSlug}`,
                section: sectionTitle,
                subsection: subsectionTitle,
                subsectionContent
              });
            }
          })
        });
      });
    }

    isObject(data) && Object.keys(data).forEach((key) => {
      const item = data[key];
      if (item?.data?.attributes?.sideBar) {
        const pageName = capitalize(key.replace("Page", ""));
        const sideBar = item.data.attributes.sideBar;
        sideBar.sectionCategories.data.forEach((category) => {
          processSectionCategory(category, pageName);
        });
      }
    });
    
    return results;
  }

  export default findSectionsWithKeyword;