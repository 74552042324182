import React, { useState } from 'react'

export const MenuContext=React.createContext()

const MenuContextProvider = ({children}) => {
    const [menu, setMenu] = useState([])
  return (
    <MenuContext.Provider value={{
        menu,
        setMenu
    }}>
        {children}
    </MenuContext.Provider>
  )
}

export default MenuContextProvider