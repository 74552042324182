import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Typography from "./Typography";
import { LoadingIcon } from "./Loading";

const LinkWrapper = ({ link, children, ...props }) =>
  link ? (
    <Link {...props} to={link}>
      {children}
    </Link>
  ) : (
    <button {...props}>{children}</button>
  );

const Button = (props) => {
  const className = [
    "Button",
    props.className,
    `Button--${props.type}`,
    `Button--${props.size}`,
    `Button--color-${props.color}`,
    props.collapseOnSmall ? "Button--collapse-on-small" : null,
  ]
    .filter((i) => i)
    .join(" ");

  return (
    <LinkWrapper
      link={props.link}
      onClick={props.onClick}
      className={className}
      disabled={props.loading || props.disabled}
    >
      {props.icon && (
        <div className="Button__icon">
          <Icon name={props.icon} />
        </div>
      )}

      <Typography type="button" className="Button__label">
        {props.text || props.children}
      </Typography>
      {props.trailingIcon && (
        <div className="Button__icon">
          <Icon name={props.trailingIcon} />
        </div>
      )}
      {props.loading && (
        <div className="Button__loading">
          <LoadingIcon />
        </div>
      )}
    </LinkWrapper>
  );
};

export default Button;

Button.propTypes = {
  // text to place in the button
  text: PropTypes.string,

  // type of button style
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "surface",
    "on-surface",
  ]),

  // size of the button
  size: PropTypes.oneOf(["small", "regular", "large"]),

  // icon the dipslay at beginning of button
  icon: PropTypes.string,

  // icon the dipslay at end of button
  trailingIcon: PropTypes.string,

  // function for button click
  onClick: PropTypes.func,

  // className for button to inherit
  className: PropTypes.string,

  // path for button to route to
  link: PropTypes.string,

  // whether or not the button is disabled
  disabled: PropTypes.bool,

  // color of teh button
  color: PropTypes.oneOf([
    "default",
    "secondary",
    "primary-light",
    "on-surface",
    "surface",
  ]),

  // whether or not the button function is loading and should be disabld
  loading: PropTypes.bool,
  children: PropTypes.string,

  // whether or not the text should disappear on a small screen
  collapseOnSmall: PropTypes.bool,
};

LinkWrapper.propTypes = {
  link: PropTypes.string,
  children: PropTypes.array,
};

Button.defaultProps = {
  type: "tertiary",
  size: "regular",
  className: "",
  disabled: false,
  color: "default",
  loading: false,
  collapseOnSmall: false,
};
