import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { WidthContext } from "../../contexts/WidthContextProvider";
import logo_org from "../../images/studio-design-logo-org.png";
import secundary_header from "../../images/secundary_header.png";
import logo_white from "../../images/logo_white.svg";
import MobileMenu from "./MobileMenu";
import HeroHeader from "../modules/HeroHeader";
import SearchBar from "../modules/SearchBar";
import MenuSurface from "../modules/MenuSurface";
import Icon from "../modules/Icon";
import buildNavQuery from "../../utils/buildNavQuery";
import { useQuery } from "@apollo/client";

const pagesToSearch = ["aboutPage", "guidelinesPage"];

const defaultLinks = [
  {
    infoPosition: "Center",
    label: "Guidelines",
    page: "guidelinesPage",
    subtitle:
      "The guidelines to help you create engaging and on-brand experiences",
    targetUrl: "/guidelines",
    title: "Design Guidelines",
  },
  {
    infoPosition: "Center",
    label: "About",
    page: "aboutPage",
    subtitle: "The Walt Disney Studios’s all-in-one design platform",
    targetUrl: "/about",
    title: "About StudioDesign",
  },
  {
    label: "Libraries",
    targetUrl: "/libraries",
    options: [
      {
        infoPosition: "Center",
        title: "Studio Design System Icons",
        subtitle: "Description",
        text: "Studio Design System Icons",
        link: "/libraries/studio-design-system-icons",
      },
      {
        infoPosition: "Center",
        title: "Presentation Icons",
        subtitle: "Description",
        text: "Presentation Icons",
        link: "/libraries/presentation-icons",
      },
    ],
  },
  // {
  //   targetUrl: "/debut",
  //   label: "Debut",
  // },
];

const TopNav = () => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const { isMobile, isSmall } = useContext(WidthContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [links, setLinks] = useState(defaultLinks);
  const { data, loading } = useQuery(
    buildNavQuery(defaultLinks.filter((link) => link.page))
  );

  if (isOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  const [heroHeaderData, setHeroHeaderData] = useState(null);

  useEffect(() => {
    if (location.pathname !== "/") {
      const currentLocation = location.pathname.split("/")[1];
      const getHeroHeaderData = () => {
        if (currentLocation !== "libraries") {
          const headerLink = links.find(
            (link) =>
              link.targetUrl.split("/")[1] === location.pathname.split("/")[1]
          );
          return headerLink && headerLink.title
            ? headerLink
            : {
                infoPosition: "Center",
                title: "Oops!",
                subtitle:
                  "Looks like there's a glitch because we couldn't find what you're searching for.",
              };
        } else {
          return links[2].options.find(
            (option) =>
              option.link.split("/").slice(-1)[0] ===
              location.pathname.split("/").slice(-1)[0]
          );
        }
      };
      setHeroHeaderData(getHeroHeaderData());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (data) {
      let newLinks = links;
      for (let page in data) {
        newLinks.forEach((pageLink) => {
          if (pageLink.page === page) {
            pageLink.targetUrl =
              pageLink.targetUrl +
              "/" +
              data[page].data.attributes.sideBar.sectionCategories.data[0]
                .attributes.slug +
              "/" +
              data[page].data.attributes.sideBar.sectionCategories.data[0]
                .attributes.sections.data[0].attributes.slug;
          }
        });
      }
      setLinks([...newLinks]);
    }
  }, [data]);

  if (isOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }
  
  if (loading) return ''

  return (
    <div
      id="header"
      className={`TopNav ${location.pathname !== "/" ? "TopNav__fixed" : ""}`}
    >
      <nav className="TopNav__container flex">
        <div className="TopNav__container flex">
          <>
            {isMobile && (
              <li className="TopNav__MenuItem">
                <MobileMenu links={links} isOpen={isOpen} setOpen={setOpen} />
              </li>
            )}
            <Link to="/">
              {/* <img src={studioDesign ? logo_org : logo_org} width="170px" /> */}
              <img
                className={isSearchOpen && isSmall ? "hide" : ""}
                src={logo_org}
                width="170px"
                alt="logo"
              />
            </Link>
            <ul>
              {!isMobile &&
                links &&
                links.map((link, index) => {
                  return !link.options ? (
                      <li
                        className={
                          link.targetUrl.split("/")[1] ===
                          location.pathname.split("/")[1]
                            ? "TopNav__MenuItem TopNav__MenuItem__active"
                            : "TopNav__MenuItem"
                        }
                        key={index}
                      >
                        <Link to={link.targetUrl}>{link.label}</Link>
                      </li>
                  ) : (
                      <li key={index}>
                        <MenuSurface
                          options={[
                            {
                              text: "Studio Design System Icons",
                              link: "/libraries/studio-design-system-icons",
                              onClick: () => {},
                            },
                            {
                              text: "Presentation Icons",
                              link: "/libraries/presentation-icons",
                              onClick: () => {},
                            },
                          ]}
                        >
                          Libraries <Icon name="caret-down" />
                        </MenuSurface>
                      </li>
                  );
                })}
            </ul>
          </>
        </div>
        <SearchBar
          setIsSearchOpen={setIsSearchOpen}
          pageNames={pagesToSearch}
        />
      </nav>
      {location.pathname !== "/" && heroHeaderData && (
        <HeroHeader
          imageSrc={secundary_header}
          title={heroHeaderData.title}
          subtitle={heroHeaderData.subtitle}
          infoPosition={heroHeaderData.infoPosition}
        />
      )}
    </div>
  );
};

export default TopNav;
