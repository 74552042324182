import React from "react";
import Button from "../modules/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <section className="page_404">
      <Helmet className="helmet">
        <title>
          404 - Not found
        </title>
      </Helmet>
      <div>
        <div className="four_zero_four_bg">
          <h1 className="text-center">404</h1>
          <h3 className="text-center">We can’t seem to find that page</h3>
          <span>
            The page you’re looking for seems to have magically disappeared
            (404). 
          </span>
            <span>Feel free to check out these pages below instead.</span>
        </div>
          <div className="link_container">
            <Link to="/">Go to Studio Design Platform</Link>
            <Link to="/debut">Go to Debut Guidelines</Link>
          </div>
      </div>
    </section>
  );
};

export default NotFound;
