import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ModalContext } from "../../contexts/ModalContextProvider";
import { capitalize } from "lodash";
import formatFileSize from "../../utils/formatFileSize";
import Button from "./Button";
import downloadFile from "../../utils/downloadFile";
import Select from "./Select";
import { LoadingIcon } from "./Loading";

const VALID_DOWNLOAD_FORMATS = [
  {
    text: "SVG",
    value: "svg",
  },
  {
    text: "PNG",
    value: "png",
  },
];

const VALID_DOWNLOAD_SIZES = [
  {
    text: "24 x 24 px",
    value: "24",
  },
  {
    text: "48 x 48 px",
    value: "48",
  },
  {
    text: "64 x 64 px",
    value: "64",
  },
  {
    text: "128 x 128 px",
    value: "128",
  },
  {
    text: "256 x 256 px",
    value: "256",
  },
];

export const ModalContent = () => {
  const { content } = useContext(ModalContext);
  const [imageData, setImageData] = useState(content)
  const [loadingImageDetails, setLoadingImageDetails] = useState(false)
  const page = capitalize(location.pathname.split("/")[1]);
  const svgRef = useRef(null);
  const format = content.img?.ext?.includes(".")
    ? content.img?.ext?.substring(1)
    : content.img?.ext;
  const [donwloadFormat, setDonwloadFormat] = useState(null);
  const [donwloadSize, setDonwloadSize] = useState(null);
  const [imageColor, setImageColor] = useState(null);
  const [svg, setSvg] = useState(null);
  const [isErrored, setIsErrored] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!imageData.img.ext){
      setLoadingImageDetails(true)
      fetch(imageData.img.url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store', 
      })
      .then((response) => {
        if (response.ok) {
          
          const size = Number(response.headers.get("content-length"));

          const imageInfo = new Image();
          imageInfo.src=imageData.img.url
          imageInfo.onload = function () {
            const width = imageInfo.width;
            const height = imageInfo.height;
            const ext = imageData.img.url.split(".").pop();

            const img = {
              width,
              height,
              size,
              ext,
            };
            setImageData({...imageData, img:{...imageData.img, ...img}})
            setLoadingImageDetails(false)
          };
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
    }
  }, [content])
  

  useEffect(() => {
    if (format === "svg" ) {
      setIsLoading(true);
      fetch(content.img.url, {
        method: "GET",
        mode: "cors",
        cache: "no-store",
      })
      .then((res) => res.text())
      .then((svgData) => {
        setSvg(svgData);
        setIsLoading(false);
      })
      .catch(setIsErrored);
    }
  }, [format]);

  useEffect(() => {
    if (svgRef) {
      const svgContainer = svgRef.current;
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          const elementsToChange = svg.querySelector("path");
          elementsToChange.style.fill = imageColor;
        }
      }
    }
  }, [imageColor]);

  const changeColor = (e) => {
    if (imageColor === e.target.value) {
      document.getElementById(e.target.id).checked = false;
      setImageColor(null);
    } else {
      setImageColor(e.target.value);
    }
  };

  return (
    content?.img && (
      <div className="Modal__content">
        <div className={`Modal__content__left ${content.isIcon ?'Modal__content__transparent':''} ${imageColor==='#ffffff' ?'Modal__content__border':''}`}>
          {format === "svg" && !window.safari? (
            isLoading?
            <LoadingIcon/>
            :
            <div
              ref={svgRef}
              className={`Modal__content__left__svgInline 
                ${isErrored ? "svgInline--errored" : ""}`}
              dangerouslySetInnerHTML={{ __html: svg }}
            />
          ) : (
            <img className={format === "svg" && window.safari?'Modal__content__left__svg':''} src={imageData.img.url} />
          )}
        </div>
        <div className="Modal__content__right">
          {
            loadingImageDetails?
            <LoadingIcon/>
            :
          <>
          <section>
            <div className="Modal__content__right__summary">
              <span>{document.title?.split(' - ')[1] || ''}</span>
              <h1>{imageData.img.name}</h1>
              <div className="Modal__content__right__summary__items">
                <span>{format || imageData.img.ext}</span>
                <span>·</span>
                <span>{formatFileSize(imageData.img.size)}</span>
                <span>·</span>
                <span>{`${imageData.img.width} x ${imageData.img.height} px`}</span>
              </div>
            </div>
          </section>
          <section>
            <span>File info</span>
            <div className="Modal__content__right__container">
              <div className="Modal__content__right__container__fileDetails">
                <div className="Modal__content__right__container__fileDetails__detail">
                  <span>Format</span>
                  {format || imageData.img.ext}
                </div>
                <div className="Modal__content__right__container__fileDetails__detail">
                  <span>File size</span> {formatFileSize(imageData.img.size)}
                </div>
                <div className="Modal__content__right__container__fileDetails__detail">
                  <span>Resolution</span>
                  {`${imageData.img.width} x ${imageData.img.height} px`}
                </div>
              </div>
            </div>
          </section>
          {content.allowDownload && (
            <section>
              <span>Download</span>
              <div className="Modal__content__right__container">
                {format === "svg" && (
                  <>
                    <span>Choose file format:</span>
                    <Select
                      options={VALID_DOWNLOAD_FORMATS}
                      onChange={(e) => setDonwloadFormat(e.value)}
                      selectedValue={donwloadFormat || "svg"}
                    />
                    <span>Choose file size:</span>
                    <Select
                      options={VALID_DOWNLOAD_SIZES}
                      onChange={(e) => setDonwloadSize(e.value)}
                      selectedValue={donwloadSize || '24'}
                    />
                    <div className="Modal__content__right__container__colorContainer">
                      <span>Color:</span>
                      <div className="Modal__custom-radios">
                        <input
                          onClick={changeColor}
                          type="radio"
                          name="color"
                          id="black"
                          value="#000000"
                        />
                        <label htmlFor="black">
                          <span className="black"></span>
                        </label>

                        <input
                          onClick={changeColor}
                          type="radio"
                          name="color"
                          id="white"
                          value="#ffffff"
                        />
                        <label htmlFor="white">
                          <span className="white"></span>
                        </label>

                        <input
                          onClick={changeColor}
                          type="radio"
                          name="color"
                          id="blue"
                          value="#236df5"
                        />
                        <label htmlFor="blue">
                          <span className="blue"></span>
                        </label>

                        <input
                          onClick={changeColor}
                          type="radio"
                          name="color"
                          id="gray"
                          value="#808080"
                        />
                        <label htmlFor="gray">
                          <span className="gray"></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div className="Modal__content__right__donwload">
                  <Button
                    className="Modal__content__right__donwload__button"
                    icon="download"
                    color="on-surface"
                    type="primary"
                    onClick={() =>
                      downloadFile(
                        content.img.url,
                        content.img.name,
                        donwloadFormat,
                        imageColor,
                        donwloadSize
                      )
                    }
                  >
                    Download
                  </Button>
                </div>
              </div>
            </section>
            )}
            </>
          }
        </div>
      </div>
    )
  );
};

const Modal = ({ children }) => {
  const { setOpen, open } = useContext(ModalContext);
  const [isClosing,setIsClosing]=useState(false)

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && open) {
        handleCloseModal()
      }
    };

    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const handleCloseModal=(e)=>{
      setIsClosing(true);
      setOpen(false);
      setTimeout(() => {
        setIsClosing(false)
      }, 750);
  }

  return (
    (open || isClosing) &&
    createPortal(
      <div id="modal" className={`Modal ${isClosing?'Modal__closing':''}`}>
        <div onClick={(e) => handleCloseModal(e)} className="Modal__close">
          <span>&times;</span>
        </div>
        {children}
      </div>,
      document.body
    )
  );
};

export default Modal;
