import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Heading } from "./Typography";
import IconsContainer from "./IconsContainer";
import downloadFile from "../../utils/downloadFile";
import { ModalContext } from "../../contexts/ModalContextProvider";
import ParseMarkdown from "./MarkdownParser";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Subsection = ({ data }) => {
  const { setOpen, setContent, open } = useContext(ModalContext);
  const {
    slug: subsectionSlug,
    title: subsectionTitle,
    publishedAt: published,
    content: subsectionContent = [],
  } = data.attributes || {};

  const currentLocation = location.pathname.split("/").slice(-1)[0];

  return (
    <main className="Subsection" id={subsectionSlug}>
      <Heading className="Subsection__title" type="heading4">
        {subsectionTitle}
        {!published && <span alt="Draft" title="Draft">✏️</span>}
      </Heading>
      <div className="Subsection__body">
        {currentLocation === "icons" && subsectionSlug === "icon-library" && (
          <IconsContainer />
        )}
        {subsectionContent.map((content, index) => (
          <div key={index}>
            {content.__typename === "ComponentContentDescription" && (
              <ParseMarkdown markdownText={content.description} />
            )}
            {content.__typename === "ComponentContentImageToDownload" && (
              <div className="Subsection__body__fileContainer">
                {content.image?.map((image) => {
                  const img = image?.image?.data?.attributes || {};
                  return (
                    <div
                      key={img.url}
                      href={img.url}
                      download={img.name}
                      className={`Subsection__body__fileContainer__column Subsection__body__fileContainer__column${
                        image.columns || "12"
                      }`}
                    >
                      <div
                        className="Subsection__body__fileContainer__column__actionButton"
                        onClick={() => downloadFile(img.url, img.name)}
                      />
                      <LazyLoadImage onClick={() => [setOpen(!open), setContent({img, allowDownload:true})]}
                        className={`Subsection__body__fileContainer__column__download ${
                          img.name?.toLowerCase()?.includes("white")
                            ? "Subsection__black"
                            : ""
                        } ${
                          /black|white|primary/.test(img.name?.toLowerCase())
                            ? "Subsection__border"
                            : ""
                        }`}
                      src={img.url}
                      alt={image.name}
                      effect="blur"
                    />
                      {image.caption && (
                        <p
                          className={`Subsection__body__fileContainer__column__download__caption${
                            image.columns === 12 ? " Subsection__center" : ""
                          }`}
                        >
                          {image.caption}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {content.__typename === "ComponentContentImageButtonContainer" && (
              <div className="Subsection__body__fileContainer">
                {content.file?.map((image) => {
                  let imgName = image.url
                    ? image.url.split("/")
                    : image.image.data?.attributes?.url || "#";
                  let href =
                    image.file?.data?.attributes?.url || image.url || "#";
                  if (Array.isArray(imgName)) {
                    imgName = imgName[imgName.length - 1];
                  } else {
                    imgName = image.file?.data?.attributes?.name;
                  }
                  return (
                    <a
                      key={image.image?.data?.attributes?.url || image.url}
                      className={`Subsection__body__fileContainer__column__download Subsection__body__fileContainer__column${
                        image.imageColumns || "12"
                      }`}
                      href={href}
                      download={imgName || ""}
                      aria-label="Download file"
                    >
                      <img
                        src={`${image.image?.data?.attributes?.url}` || "#"}
                        alt="download_source"
                      />
                    </a>
                  );
                })}
              </div>
            )}
            {content.__typename === "ComponentContentColorFile" && (
              <a
                className="link-download"
                href={content.fileToDownload?.data?.attributes?.url || "#"}
                download={content.fileToDownload?.data?.attributes?.name || ""}
              >
                <ParseMarkdown markdownText={content.HTMLComponent} />
              </a>
            )}
            {content.__typename === "ComponentContentTip" && (
              <div className="Subsection__body__note">
                <ParseMarkdown markdownText={content.note} />
              </div>
            )}
          </div>
        ))}
        {currentLocation === "production-technology" &&
          subsectionSlug === "prod-icons" && <IconsContainer />}
      </div>
    </main>
  );
};

export default Subsection;
