import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_ICONS,
  GET_ICONS_CATEGORIES,
  GET_ICONS_COLLECTIONS,
} from "../queries";

const useIcons = () => {
  const showLocalStorageSize = false;
  if (localStorage.iconsLibraryIndex === undefined)
    localStorage.setItem("iconsLibraryIndex", JSON.stringify([]));
  if (localStorage.iconsLibrary === undefined)
    localStorage.setItem("iconsLibrary", JSON.stringify([]));
  const location = useLocation();
  const [iconsLibraryIndex, setIconsLibraryIndex] = useState(
    JSON.parse(localStorage.iconsLibraryIndex)
  );
  const [iconsLibrary, setIconsLibrary] = useState(
    JSON.parse(localStorage.iconsLibrary)
  );
  const iconsPerPage = 24;
  const [totalIcons, setTotalIcons] = useState(400);
  const [totalPages, setTotalPages] = useState(99);
  const [loadedIcons, setLoadedIcons] = useState([]);
  const [displayIcons, setDisplayIcons] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [nextPageIndex, setNextPageIndex] = useState(null);
  const [sort, setSort] = useState("asc");
  const [filterByCollection, setFilterByCollection] = useState("99");
  const [searchTerm, setSearchTerm] = useState("");
  const [iconsCategory, setIconsCategory] = useState({
    id: null,
    name: null,
    packageUrl: null,
  });
  const [iconsCategories, setIconsCategories] = useState([]);
  const defaultCollectionsState = [
    {
      id: "99",
      name: iconsCategory.name,
      text: "All",
      packageUrl: iconsCategory.packageUrl,
      isCurrent: false,
      onClick: () => setFilterByCollection("99"),
    },
  ];
  const [iconsCollections, setIconsCollections] = useState([
    ...defaultCollectionsState,
  ]);

  const currentSlug = location.pathname.split("/").slice(-1)[0];

  const iconsCategorySlug =
    currentSlug === "icons"
      ? "studio-design-system-icons"
      : location.pathname.split("/").slice(-1)[0];

  const [downloadProgress, setDownloadProgress] = useState();

  const {
    data: iconsCategoriesData,
    loading: iconsCategoriesLoading,
    //error: iconsCategoriesError,
  } = useQuery(GET_ICONS_CATEGORIES);

  const {
    data: iconsData,
    loading: iconsLoading,
    //error: iconsError,
  } = useQuery(GET_ICONS, {
    variables: {
      ICONS_CATEGORY_SLUG: iconsCategorySlug,
      PAGE_SIZE: iconsPerPage,
      PAGE: pageIndex,
    },
  });

  const {
    data: iconsCollectionsData,
    loading: iconsCollectionsLoading,
    refetch: iconsCollectionsRefetch,
  } = useQuery(GET_ICONS_COLLECTIONS);

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    setPageIndex(1);
    setTotalIcons(400);
    setTotalPages(99);
    setLoadedIcons([]);
    setIconsCollections([...defaultCollectionsState]);
    iconsCollectionsRefetch;
    setIconsCategories(
      iconsCategories.map((category) => {
        return {
          ...category,
          isActive: category.slug === currentSlug ? true : false,
        };
      })
    );
  }, [iconsCategorySlug]);

  useEffect(() => {
    if (iconsCategories.length > 0) {
      const currentCategoryId = iconsCategories.find(
        (category) => category.slug === iconsCategorySlug
      ).id;
      if (iconsLibrary.length > 0) {
        setLoadedIcons([
          ...iconsLibrary.filter(
            (icon) => icon.categoryId === currentCategoryId
          ),
        ]);
        setDisplayIcons([
          ...iconsLibrary.filter(
            (icon) => icon.categoryId === currentCategoryId
          ),
        ]);
      }
    }
  }, [iconsCategories, iconsLibrary]);

  useEffect(() => {
    if (!iconsCategoriesLoading) {
      const iconsCategoriesToState =
        iconsCategoriesData.iconCategories.data.map((category) => {
          return {
            id: category.id,
            slug: category.attributes.slug,
            name: category.attributes.categoryName,
            packageUrl: category.attributes.categoryPackage.data.attributes.url,
            isActive: category.attributes.slug === currentSlug ? true : false,
          };
        });
      setIconsCategories([...iconsCategoriesToState]);
      const currentCategory = iconsCategoriesData.iconCategories.data.find(
        (iconsCategory) => iconsCategory.attributes.slug === iconsCategorySlug
      );
      const iconsCategoryToState = {
        id: currentCategory.id,
        name: currentCategory.attributes.categoryName,
        packageUrl:
          currentCategory.attributes.categoryPackage.data.attributes.url,
      };
      setIconsCategory(iconsCategoryToState);
      const iconsCollectionsToState = iconsCollections.map((collection) => {
        if (collection.id === "99") {
          return {
            ...collection,
            packageUrl:
              currentCategory.attributes.categoryPackage.data.attributes.url,
          };
        }
        return { ...collection };
      });
      setIconsCollections(iconsCollectionsToState);
    }
  }, [iconsCategoriesLoading, iconsCategorySlug]);

  // Loaded collections to state
  useEffect(() => {
    if (!iconsCollectionsLoading) {
      const availableCollections = Array.from(
        new Set(
          loadedIcons
            .filter((icon) => icon.collectionId !== null)
            .map((filteredIcon) => filteredIcon.collectionId)
        )
      );
      const iconsCollectionsToState = iconsCollectionsData.iconCollections.data
        .map((collection) => {
          return {
            id: collection.id,
            name: collection.attributes.collectionName,
            text: collection.attributes.collectionName,
            packageUrl:
              collection.attributes.collectionPackage.data.attributes.url,
            isCurrent: false,
            onClick: () => setFilterByCollection(collection.id),
          };
        })
        .filter((availableCollection) =>
          availableCollections.includes(availableCollection.id)
        );
      setIconsCollections([
        ...defaultCollectionsState,
        ...iconsCollectionsToState,
      ]);
    }
    const currentPageIndex =
      loadedIcons.length % iconsPerPage === 0
        ? loadedIcons.length / iconsPerPage + 1
        : Math.ceil(loadedIcons / iconsPerPage);
    setPageIndex(currentPageIndex);
  }, [iconsCollectionsLoading, iconsCategorySlug, loadedIcons]);

  // Filter by collection
  useEffect(() => {
    if (filterByCollection !== "99") {
      const iconsToState = loadedIcons.filter(
        (icon) => icon.collectionId === filterByCollection
      );
      setDisplayIcons(iconsToState);
      setSort("asc");
    } else {
      setDisplayIcons(loadedIcons);
      setSort("asc");
    }
  }, [filterByCollection]);

  useEffect(() => {
    if (!iconsLoading && iconsData) {
      setNextPageIndex(pageIndex + 1);
      if (nextPageIndex <= totalPages) {
        loadMore();
      }
    }
  }, [iconsData, iconsLoading]);

  const loadMore = () => {
    if (!iconsLoading && iconsData) {
      const newIcons = iconsData.iconFiles.data;

      const newIconsIndex = newIcons
        .filter((icon) => !iconsLibraryIndex.includes(icon.id))
        .map((icon) => icon.id);

      if (newIconsIndex.length > 0) {
        setLoadedIcons([...loadedIcons, ...newIcons]);
        setIconsLibraryIndex(
          [...new Set([...iconsLibraryIndex, ...newIconsIndex])].sort(
            (a, b) => a - b
          )
        );
        const iconsToLibrary = newIcons
          .filter((icon) => newIconsIndex.includes(icon.id))
          .map((icon) => {
            return {
              id: icon.id,
              categoryId: icon.attributes.icon_category.data.id,
              collectionId: !icon.attributes.icon_collection.data
                ? null
                : icon.attributes.icon_collection.data.id,
              image: { ...icon.attributes.image.data.attributes },
              title: icon.attributes.title,
            };
          });
        setIconsLibrary([...new Set([...iconsLibrary, ...iconsToLibrary])]);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "iconsLibraryIndex",
      JSON.stringify([...iconsLibraryIndex])
    );
  }, [iconsLibraryIndex]);

  //TODO: Show local storage size enable 'showLocalStorageSize' at begin of useIconsHook
  useEffect(() => {
    let localStorageSize = function () {
      let _lsTotal = 0,
        _xLen,
        _x;
      for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) continue;
        _xLen = (localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
      }
      return (_lsTotal / 1024).toFixed(2);
    };
    if (showLocalStorageSize) {
      console.log(`local storage size: ${localStorageSize()}kb`);
    }

    localStorage.setItem("iconsLibrary", JSON.stringify([...iconsLibrary]));
  }, [iconsLibrary]);
  //TODO: Show local storage size enable 'showLocalStorageSize' at begin of useIconsHook

  useEffect(() => {
    if (loadedIcons.length === pageIndex * iconsPerPage) {
      setPageIndex(nextPageIndex);
    }
  }, [loadedIcons]);

  useEffect(() => {
    if (iconsData) {
      if (totalIcons !== iconsData.iconFiles.meta.pagination.total) {
        setTotalIcons(iconsData.iconFiles.meta.pagination.total);
      }
      if (iconsData.iconFiles.meta.pagination.pageCount !== totalPages) {
        setTotalPages(iconsData.iconFiles.meta.pagination.pageCount);
      }
    }
  }, [iconsData]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const searchResult = loadedIcons.filter((icon) =>
        icon.title.toLowerCase().includes(searchTerm)
      );
      setDisplayIcons(searchResult);
    } else {
      setDisplayIcons(loadedIcons);
    }
  }, [searchTerm]);

  useEffect(() => {
    const sortedIcons = sortIcons(displayIcons, sort);
    setDisplayIcons(sortedIcons);
  }, [sort]);

  const handleIconsSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const menuSurfaceOptions = [
    {
      text: "Sort by: Title A-Z",
      onClick: () => setSort("asc"),
    },
    {
      text: "Sort by: Title Z-A",
      onClick: () => setSort("desc"),
    },
  ];

  const sortIcons = (array, ascending) => {
    const newArray = [...array];
    return newArray.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      let comparison = 0;
      if (titleA > titleB) {
        comparison = 1;
      } else if (titleA < titleB) {
        comparison = -1;
      }
      return ascending === "asc" ? comparison : -comparison;
    });
  };

  return {
    displayIcons,
    filterByCollection,
    iconsCategory,
    iconsCollections,
    loadedIcons,
    menuSurfaceOptions,
    sort,
    totalIcons,
    downloadProgress,
    setDownloadProgress,
    handleIconsSearch,
  };
};

export default useIcons;
