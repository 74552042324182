import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { WidthContext } from "../../contexts/WidthContextProvider";
import logo_org from "../../images/debut/debut_logo.png";
import MobileMenu from "./MobileMenu";
import SearchBar from "../modules/SearchBar";
import { UserContext } from "../../contexts/UserContextProvider";
import buildNavQuery from "../../utils/buildNavQuery";
import { useQuery } from "@apollo/client";
import MenuSurface from "../modules/MenuSurface";
import Icon from "../modules/Icon";
import HeroHeader from "../modules/HeroHeader";

const pagesToSearch = [
  "aboutDebutPage",
  "accessAndSupportPage",
  "workingWithDebutPage",
  "imageGuidelinesPage",
  "watermarkingPage",
];

const initialLinks = [
  {
    page: "aboutDebutPage",
    targetUrl: "/aboutdebut",
    title: "What is Debut?",
  },
  {
    page: "accessAndSupportPage",
    targetUrl: "/accessandsupport",
    title: "Access & Support ",
  },
  {
    label: "Guidelines",
    options: [
      {
        page: "workingWithDebutPage",
        link: "/workingwithdebut",
        text: "Working with Debut",
        title: "Working with Debut",
        onClick: () => {},
      },
      {
        page: "imageGuidelinesPage",
        link: "/imageguidelines",
        text: "Image Guidelines",
        title: "Image Guidelines",
        onClick: () => {},
      },
      {
        page: "watermarkingPage",
        link: "/watermarking",
        text: "Watermarking",
        title: "Watermarking",
        onClick: () => {},
      },
      {
        page: "videoDeliveryGuidelinesPage",
        link: "/videodelivery",
        text: "Video Delivery Guidelines",
        title: "Video Delivery Guidelines",
        onClick: () => {},
      },
    ],
  },
];


const DebutNav = () => {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const { isLarge, isMobile, isSmall } = useContext(WidthContext);
  const { user } = useContext(UserContext);
  const [navbarLinks, setNavbarLinks] = useState(initialLinks);
  const [searchPages, setSearchPages] = useState(pagesToSearch);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { data, loading, refetch } = useQuery(
    buildNavQuery((navbarLinks?.map((link) => link.options? link.options.map(option=>option):link)).flat(1))
  );

  useEffect(() => {
    if (
      user &&
      user.role &&
      user.role.name === "Debut Administrator" &&
      !searchPages.includes("appDocumentationPage")
    ) {
      setSearchPages([...searchPages, "appDocumentationPage"]);
      const newLink = {
        page: "appDocumentationPage",
        link: "/appdocumentation",
        text: "App Documentation",
        title: "App Documentation",
        onClick: () => {},
      };
      const newLinks = [...navbarLinks];
      newLinks[2].options.push(newLink);
      setNavbarLinks(newLinks);
      refetch();
    }
  }, [user, refetch]);

  useEffect(() => {
    if (data) {
      let newLinks = navbarLinks;
      for (let page in data) {
        newLinks.forEach((pageLink) => {
          if(!pageLink.options){
            if (pageLink.page === page) {
              pageLink.targetUrl =
              pageLink.targetUrl +
              "/" +
              data[page]?.data.attributes.sideBar.sectionCategories?.data[0]
              .attributes.slug +
              "/" +
              data[page]?.data.attributes.sideBar.sectionCategories?.data[0]
              .attributes.sections.data[0].attributes.slug;
            }
          }else{
            pageLink.options.forEach((optionLink) => {
              if (optionLink.page === page) {
                optionLink.link =
                optionLink.link +
                "/" +
                data[page]?.data.attributes.sideBar.sectionCategories?.data[0]
                .attributes.slug +
                "/" +
                data[page]?.data.attributes.sideBar.sectionCategories?.data[0]
                .attributes.sections.data[0].attributes.slug;
              }
          })}
        });
      }
      setNavbarLinks([...newLinks]);
    }
  }, [data]);

  if (isOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  if (loading) return "";

  return (
    <div id="header">
    <div className={`TopNav Debut__navBar flex center`} id="navbar">
      <nav className="TopNav__container flex Debut__navBar__container">
        <div className="TopNav__container flex">
          <>
            {isMobile && (
              <li className="TopNav__MenuItem">
                <MobileMenu
                  links={navbarLinks}
                  isOpen={isOpen}
                  setOpen={setOpen}
                />
              </li>
            )}
            <Link to="/debut" aria-label="home_link">
              <img
                className={isSearchOpen && isSmall ? "hide" : ""}
                src={logo_org}
                width="170px"
                alt="logo"
              />
            </Link>
            
          </>
        </div>
        <div className="flex">
        <ul className={isSearchOpen ? "hide" : ""}>
              {!isMobile &&
                navbarLinks &&
                navbarLinks.map((link, index) => {
                  return !link.options ? (
                    <li
                      className={
                        link.targetUrl?.split("/")[1] ===
                        location.pathname?.split("/")[1]
                          ? "TopNav__MenuItem TopNav__MenuItem__active"
                          : "TopNav__MenuItem"
                      }
                      key={index}
                    >
                      <Link to={link.targetUrl}>{link.title}</Link>
                    </li>
                  )
                    : (
                      <li key={index}>
                        <MenuSurface
                          options={link.options}
                        >
                          {link.label} <Icon name="caret-down" />
                        </MenuSurface>
                      </li>
                  );
                })}
            </ul>
        <SearchBar setIsSearchOpen={setIsSearchOpen} pageNames={searchPages} />
        </div>
      </nav>
      </div>
       
    </div>
  );
};

export default DebutNav;
