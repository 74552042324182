import JSZip from "jszip";

const fetchIconFile = async (fileUrl) => {
  const response = await fetch(fileUrl, { mode: "cors", cache: "no-store" });
  if (!response.ok) {
    throw new Error(`Error trying to fetch icon file from ${url}`);
  }
  return response.text();
};

const downloadIconsPackage = async (
  packageTypeId,
  packageId,
  packageName,
  handleDownloadProgress
) => {
  const appIcons = JSON.parse(localStorage.iconsLibrary);
  const packageElements = appIcons.filter((icon) =>
    packageTypeId === 0
      ? icon.categoryId === packageId
      : icon.collectionId === packageId
  );

  const zipFile = new JSZip();
  const iconsFiles = [];
  let progress = 0;
  for (const icon of packageElements) {
    const svgFileContent = await fetchIconFile(icon.image.url);
    iconsFiles.push({
      name: `${icon.title.toLowerCase().replace(/\s+/g, "-")}.svg`,
      content: svgFileContent,
    });
    progress++;
    let percent = Math.ceil((progress / packageElements.length) * 100);
    handleDownloadProgress(percent);
  }
  iconsFiles.forEach(({ name, content }) => {
    zipFile.file(name, content);
  });
  const iconsPackage = await zipFile.generateAsync({ type: "blob" });
  const zipIconsPackage = URL.createObjectURL(iconsPackage);
  const link = document.createElement("a");
  link.href = zipIconsPackage;
  link.download = `${packageName.toLowerCase().replace(/\s+/g, "-")}${
    packageTypeId === 0 ? `-category` : `-collection`
  }-icons-package.zip`.toLowerCase();
  document.body.appendChild(link);
  link.click();
  handleDownloadProgress(null);
  document.body.removeChild(link);
};

export default downloadIconsPackage;
