function getLastModficiation(data) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let lastModification = data.updatedAt;
  data.subsections?.data?.forEach((element) => {
    const subsectionDate = element.attributes.updatedAt;
    if (subsectionDate > lastModification) {
      lastModification = subsectionDate;
    }
  });
  const date = new Date(lastModification);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDate = `${day}. ${month} ${year} ${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;

  return formattedDate;
}

export default getLastModficiation;
