import { gql } from "@apollo/client";
export const GET_ICONS_CATEGORIES = gql`
  query {
    iconCategories(sort: "id:asc") {
      data {
        id
        attributes {
          categoryName
          slug
          categoryPackage {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
