import React from "react";
import PropTypes from "prop-types";
import { Body } from "./Typography";
import Icon from "./Icon";
import MenuSurface from "./MenuSurface";
import { Link } from "react-router-dom";

const MenuSurfaceOption = (props) => {
  let onClick;
  if (props.onClick)
    onClick = (e) => {
      //e.preventDefault();
      props.onClick();
      props.closeParentMenu();
    };

  return (
    <li className="MenuSurfaceOption">
      <MenuWrapper
        subMenu={props.subMenu}
        closeParentMenu={props.closeParentMenu}
      >
        <LinkWrapper
          link={props.link}
          className="MenuSurfaceOption__button"
          onClick={onClick}
        >
          {props.icon && (
            <Icon faded className="MenuSurfaceOption__icon" name={props.icon} />
          )}
          <Body className="MenuSurfaceOption__button__text" number={2}>
            {props.text}
          </Body>
        </LinkWrapper>
      </MenuWrapper>
    </li>
  );
};

const LinkWrapper = ({ link, children, ...props }) =>
  link ? (
    <Link to={link} {...props}>
      {children}
    </Link>
  ) : (
    <button {...props} type="button">
      {children}
    </button>
  );

const MenuWrapper = ({ subMenu, children, ...props }) =>
  subMenu && subMenu.length ? (
    <MenuSurface {...props} isSubMenu={true} options={subMenu}>
      {children}
    </MenuSurface>
  ) : (
    children
  );

export default MenuSurfaceOption;

MenuSurfaceOption.propTypes = {
  // text to display for option
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  // icon to prepend to text
  icon: PropTypes.string,

  // link option routes to
  link: PropTypes.string,

  // value of the option
  value: PropTypes.string,

  // function to handle option selection
  onClick: PropTypes.func,

  // submenu options
  subMenu: PropTypes.array,

  // function to close the entire menu
  closeParentMenu: PropTypes.func.isRequired,
};
