import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import MenuSurfaceOption from './MenuSurfaceOption';
import TextField from './TextField';
import { Caption } from './Typography';


const MenuSurface = props => {
  const [ open, setOpen ] = useState(false);
  const menuRef = useRef(null);
  const [ xFlip, setXFlip ] = useState(false);
  const [ yFlip, setYFlip ] = useState(false);
  const [ search, setSearch ] = useState("");

  const openMenu = () => setOpen(true);
  const closeMenu = () => {
    setOpen(false);
    if (props.closeParentMenu) props.closeParentMenu();
  };

  // handle organic close
  const handleClick = useCallback((e) => {
    if (menuRef && !menuRef.current.contains(e.target)) {
      setOpen(false);
    }
  }, [ setOpen ])

  useEffect(() => {
    if (open) window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [ open, handleClick ])

  // handle organic positioning
  useEffect(() => {
    const ref = menuRef ? menuRef.current : null;
    if (open && ref) {
      const { right, bottom } = ref.getBoundingClientRect();
      if (right > window.innerWidth) setXFlip(true);
      if (bottom > window.innerHeight) setYFlip(true);
    }

    const clearPositioning = () => {
      if (!open) {
        setXFlip(false);
        setYFlip(false)
      }
    }

    if (ref) ref.addEventListener('transitionend', clearPositioning);
    return () => {
      if (ref) ref.removeEventListener('transitionend', clearPositioning);
    }
  }, [ open ])


  // filter search
  useEffect(() => {
    if (!open) setSearch("")
  }, [ open ]);
  let options = props.options || [];
  if (props.searchable && search && options.length) {
    options = options
      .filter(option => typeof option.text === "string")
      .filter(option => search
        .trim()
        .toLowerCase()
        .split(" ")
        .every(term => option.text.toLowerCase().includes(term)
      ));
  }

  return (
    <div className={[
      "MenuSurface",
      props.className,
      open ? "MenuSurface--open" : "MenuSurface--close",
      xFlip ? "MenuSurface--x-flip" : null,
      yFlip ? "MenuSurface--y-flip" : null,
    ].filter(i => i).join(" ")}>
      <div className={`MenuSurface__button ${props.disabled ? "MenuSurface__button--disabled" : ""}`} onClick={openMenu}>
        {props.children}
      </div>

      {props.searchable && open && <TextField
        className="Select__searchable"
        value={search}
        icon="search"
        autofocus
        onChange={e => setSearch(e.target.value)}
        placeholder="Start typing to search..."
      />}

      <ul
        className={[
          "MenuSurface__menu",
          props.isSubMenu ? "MenuSurface__menu--submenu" : null,
          props.className ? `${props.className}__MenuSurface__menu` : null
        ].filter(i => i).join(" ")}
        ref={menuRef}
      >
        {props.searchable && open && search && options.length === 0 && <li className="padded">
          <Caption faded>No results found.</Caption>
        </li>}
        {options.map((option, index) => (
          <MenuSurfaceOption key={index} {...option} closeParentMenu={closeMenu} />
        ))}
      </ul>
    </div>
  )
}

export default MenuSurface;

MenuSurface.propTypes = {
  // all options for the drop down menu
  options: PropTypes.array,

  // className to passdown to tags
  className: PropTypes.string,

  // whether or not the menu provided is a submenu
  isSubMenu: PropTypes.bool,

  // function to close the entire menu
  closeParentMenu: PropTypes.func,

  // whether or not the menu surface is disabled
  disabled: PropTypes.bool,

  // whether or not the list is searchable by listening to window keypresses
  searchable: PropTypes.bool
}

MenuSurface.defaultProps = {
  className: "",
  isSubMenu: false,
  disabled: false,
  searchable: false
}