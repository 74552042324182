function formatFileSize(fileSizeInKB) {
  if (fileSizeInKB < 1024) {
    return Math.ceil(fileSizeInKB) + " KB";
  } else if (fileSizeInKB < 1024 * 1024) {
    const fileSizeInMB = fileSizeInKB / 1024;
    return Math.ceil(fileSizeInMB) + " MB";
  } else {
    const fileSizeInGB = fileSizeInKB / (1024 * 1024);
    return Math.ceil(fileSizeInGB) + " GB";
  }
}

export default formatFileSize;
