import React, { Fragment, useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import Cookies from "universal-cookie";
import { UserContext } from "../../contexts/UserContextProvider";
import { LOGIN } from "../../queries";
import Button from "../modules/Button";
import TextField from "../modules/TextField";
import { Body, Caption } from "../modules/Typography";
import Icon from "../modules/Icon";

const LoginVendor = (props) => {
  const cookies = new Cookies();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [login] = useMutation(LOGIN);
  const { setUser } = useContext(UserContext);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleEmail = (e) => {
    if (error) setError(false);
    setEmail(e.target.value);
  };

  const handleLogin = async (e) => {
    if (validateEmail(email)) {
      try {
        e.preventDefault();
        const { data } = await login({
          variables: {
            input: {
              identifier: email,
              password,
              provider: "local",
            },
          },
        });
        const { user, jwt } = data.login;
        cookies.set("access_token", jwt, { path: "/" });
        setUser(user);
      } catch (err) {
        setError(
          "The password does not match the email provided. Please re-enter the correct password or reset."
        );
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      handleLogin(e);
    }
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    const isValidEmail = re.test(email);
    if (!isValidEmail) setError("Please use a valid email format.");
    return isValidEmail;
  };

  return (
    <Fragment>
      <Body number={1} block className="Login__non-disney">
        Not a part of Disney?
      </Body>
      <TextField
        type="email"
        value={email}
        placeholder="Enter Email"
        onChange={handleEmail}
        autofocus
      />
        <TextField
          icon="" // TO DO: can't find eye-icon, need to be added
          type="password"
          value={password}
          placeholder="Enter Password"
          onChange={handlePassword}
          onKeyPress={handleKeyPress}
        />
      {error && (
        <Caption
          number={3}
          className="Login__error-message"
          block
          error
          topPadded
        >
          {error}
        </Caption>
      )}
      <Button className="Login__signin" type="primary" onClick={handleLogin}>
        Sign In
      </Button>
    </Fragment>
  );
};

export default LoginVendor;
